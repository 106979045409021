import React, { useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import { SmartAppBannerStyled, GridContentStyled } from './PageStyles.js';
import mobileAppLogo from '../../img/mobile-app-logo.png';

export default function SmartAppBanner(props) {
    const [bannerOn, setBannerOn] = useState(false);
    const [cookies, setCookie] = useCookies(['bannerDismissed']);

    let data;

    switch (props.os) {
        case "iOS":
            data = {
                text: 'FREE - On the App Store',
                url: 'https://apps.apple.com/us/app/livewell-with-advocate-aurora/id938360989'
            }
            break;
        case "Android":
            data = {
                text: 'FREE - On the Play Store',
                url: 'https://play.google.com/store/apps/details?id=com.advocate.myadvocate&hl=en_US&gl=US'
            }
            break;
        default:
            data = {
                text: '',
                url: ''
            }
    }

    useEffect(() => {
        if (!cookies.bannerDismissed) {
            setBannerOn(true);
        }        
    }, []);

    function closeBanner() {
        // set cookie after banner dismissed - expires in 15 days
        const expirationDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * 15));
        setCookie('bannerDismissed', true, { path: "/", expires: expirationDate });        
        setBannerOn(false);
    }

    return (
        <>  
            {bannerOn && <SmartAppBannerStyled>
                <div className="grid-item">
                    <GridContentStyled onClick={closeBanner}>
                        <span className="close-btn">
                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509277 8.57031C0.32666 8.75293 0.321289 9.06445 0.509277 9.24707C0.697266 9.42969 1.00879 9.42969 1.19141 9.24707L4.62891 5.80957L8.06641 9.24707C8.24902 9.42969 8.56592 9.43506 8.74854 9.24707C8.93115 9.05908 8.93115 8.75293 8.74854 8.57031L5.31104 5.12744L8.74854 1.68994C8.93115 1.50732 8.93652 1.1958 8.74854 1.01318C8.56055 0.825195 8.24902 0.825195 8.06641 1.01318L4.62891 4.45068L1.19141 1.01318C1.00879 0.825195 0.691895 0.819824 0.509277 1.01318C0.32666 1.20117 0.32666 1.50732 0.509277 1.68994L3.94678 5.12744L0.509277 8.57031Z" fill="#707070"/>
                            </svg>
                        </span>
                    </GridContentStyled>
                </div>
                <div className="grid-item">
                    <GridContentStyled>
                        <img src={mobileAppLogo} alt="mobile app logo" title="App logo" />
                    </GridContentStyled>
                </div>
                <div className="grid-item">
                    <GridContentStyled className="text-container">
                        <div className="title">LiveWell with<br/> Advocate Aurora Health</div>
                        <div className="subtitle">Advocate Aurora Health Care</div>
                        <div className="text">{data.text}</div>
                    </GridContentStyled>
                </div>
                <div className="grid-item">
                    <GridContentStyled>
                        <a className="banner-link" href={data.url}>View</a>
                    </GridContentStyled>
                </div>
            </SmartAppBannerStyled>}
        </>
    );
}