const esTranslations = {
  // CurrentPageNav.js
  home: "Inicio", // not in spreadsheet
 
  // Footer.js
  faq: "Preguntas frecuentes",
  livewell_privacy_policy: "LiveWell Política de privacidad",
  livewell_terms_of_use: "LiveWell Términos de Uso", // not in spreadsheet
  notice_of_privacy_practices: "Aviso de Prácticas de Privacidad", // not in spreadsheet
  notice_of_non_discrimination: "Aviso de No Discriminación", // not in spreadsheet
 
  // Meditations.js
  guided_meditations: "Meditaciones Guiadas",
  guided_meditations_description: "Encuentre calma en su ajetreado día con nuestras meditaciones guiadas. Practique en cualquier momento y en cualquier lugar para disminuir el estrés, mejorar el sueño e incrementar la consciencia.", 
  
  // MeditationsFeatured.js
  see_more: "Ver más",
  
  // MyChartModal.js
  mychart_modal_blurb: "Inicie sesión a continuación o cierre esta ventana para explorar LiveWell como invitado.",
  make_a_secure_online_payment: "Realice un pago en línea seguro: ",
  no_account_or_sign_in: "no es necesario abrir una cuenta ni iniciar sesión.",

  // Nav.js
  wellness: "Bienestar",
  mychart: "MyChart", // Proper name
  find_care: "Buscar", 
  health_e_news_articles: "Artículos de Noticias de Salud", // not in spreadsheet
  recipes: "Recetas",
  meditations: "Meditaciones",

  // PageSection.js
  show_all: "Mostrar todos",

  // SignInButton.js
  sign_in: "Iniciar sesión", 
  to: "en", // not in spreadsheet
};

export default esTranslations;
