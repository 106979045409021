import './App.css';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { UAParser } from 'ua-parser-js';
import Routes from './Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import SmartAppBanner from './components/Page/SmartAppBanner';
import ScrollToTop from './hooks/ScrollToTop';
import UseEmergencyAlert from './hooks/UseEmergencyAlert';
import GlobalContext from './GlobalContext';
import { useEffect } from 'react';
import UseAuth from './hooks/UseAuth';
import './i18n';

// kick off smooth scroll polyfill
smoothscroll.polyfill();

// instantiate UAParser for detecting user OS
const parser = new UAParser();

function App() {
  const emergencyAlert = UseEmergencyAlert();

  const os = parser.getResult().os.name;
  const showSmartAppBanner = (os === "iOS" || os === "Android");

  const { token, fetchAuth } = UseAuth();

  useEffect(() => {
    if (!token) {
        fetchAuth();
    }
}, []);

  return (
    <GlobalContext.Provider value={{emergencyAlert, token}}>
      <BrowserRouter>
        {showSmartAppBanner && <SmartAppBanner os={os} />}
        <Header />
        <ScrollToTop />
        <Routes />
        <Footer />
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default App;
