import styled from 'styled-components';

const hasMouseAndSupportsHover = '(pointer: fine) and (hover: hover)';

export const CarrouselStyledDefault = styled.div`
    position: relative;
    .slides-container {
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Hide horizontel scroll bar IE and Edge */
        scrollbar-width: none;  /* Hide horizontel scroll bar Firefox */
        &::-webkit-scrollbar {
            display: none; /* Hide horizontel scroll bar or Chrome, Safari and Opera */
        }
        width: calc(100vw - 1rem);
        & > div {            
            margin-bottom: 4px;
        }
        @media (min-width: 769px) and ${hasMouseAndSupportsHover} {               
            width: calc(100%);            
            max-width: 100vw;
            overflow-y: hidden;
            overflow-x: auto;
            scroll-behavior: smooth;

            &.mask-image {
                mask-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5) 2.5rem, rgba(0, 0, 0, 1.0) 5rem, rgba(0, 0, 0, 1.0) calc(100% - 5rem), rgba(0, 0, 0, 0.5) calc(100% - 2.5rem), transparent);
                
                &.modal-open {
                    mask-image: none;
                }
            }

            &.mask-image-right-only {
                mask-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 1) 0rem, rgba(0, 0, 0, 1.0) 5rem, rgba(0, 0, 0, 1.0) calc(100% - 5rem), rgba(0, 0, 0, 0.5) calc(100% - 2.5rem), transparent);
                
                &.modal-open {
                    mask-image: none;
                }
            }
        }
        @media (min-width: 1200px) and ${hasMouseAndSupportsHover} {
            margin-left: -5rem;
            width: calc(100% + 10rem);            
            max-width: 100vw;
            & > *:first-child {
                margin-left: 5rem;
            }

            & > *:last-child {
                position: relative;
                &::after {
                    position: absolute;
                    content: '';
                    right: -5rem;
                    width: 5rem;
                    height: 100%;
                }
            }
            
            &.mask-image {
                mask-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5) 2.5rem, rgba(0, 0, 0, 1.0) 5rem, rgba(0, 0, 0, 1.0) calc(100% - 5rem), rgba(0, 0, 0, 0.5) calc(100% - 2.5rem), transparent);
                
                &.modal-open {
                    mask-image: none;
                }
            }            
        }
    }   
`;

export const CarrouselControlsStyled = styled.div`
    .scroll-left-container {
        color: #0f0f0f;
        position: absolute;
        top: -1%;
        left: -7rem;
        width: 5rem;
        height: 100%;
        &:hover {
            cursor: pointer;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
        }

        &.hide {
            display: none;
        }

    }

    .scroll-right-container {
        display: none;
        color: #0f0f0f;
        position: absolute;
        top: -1%;
        right: -7rem;
        width: 5rem;
        height: 100%;
        &:hover {
            cursor: pointer;
        }

        svg {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
        }

        &.hide {
            display: none;
        }

    }
    
    @media (min-width: 769px) and ${hasMouseAndSupportsHover} {
        .scroll-left-container {
            display: block;
            left: -1rem;
        }
        .scroll-right-container {
            display: block;
            right: -1rem;
        }
    }

    @media (min-width: 1200px) and ${hasMouseAndSupportsHover} {
        .scroll-left-container {
            left: -7rem;

            svg {
                position: absolute;
                top: 50%;
                right: 33%;
                left: auto;
                transform: translate(-50%, -50%);
            }
        }
        .scroll-right-container {
            right: -7.5rem;

            svg {
                position: absolute;
                top: 50%;
                left: 33%;
                transform: translate(-50%, -50%);
            }
        }
    }
`;

export const CarrouselStyled = styled(CarrouselStyledDefault)`
    &.manual-image-scroller {
        .slides-container {
            mask-image: none;
            width: 100%;
            margin: 0;
            overflow-y: hidden;
            overflow-x: hidden;

            & > div:first-child, & > a:first-child {
                margin-left: 0;
            }

            &.mask-image {
                mask-image: none;
            }

            &.mask-image-right-only {
                mask-image: none;
            }
        }        
    }
`;

export const IndicatorsContainerStyled = styled.div`
    display: none;
    text-align: center;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &.hero {
        display: block;

        &.show-never {
            display: none;
        }
    }
    @media (min-width: 769px) and ${hasMouseAndSupportsHover} {              

        &.section {
            display: none;
            &.show-on-hover {
                display: block;
            }

            &.show-never {
                display: none;
            }
        }
    }
`;

export const CarrouselIndicatorStyled = styled.span`
    .svg {
        fill: #cecece;
        margin: .62rem .31rem 0;
        width: 13px;
        height: 13px;

        @media (min-width: 1025px) and ${hasMouseAndSupportsHover} {
            width: 13px;
            height: 13px;
        }

        &.active {
            fill: #7b7b7b;
        }

        &:hover {
            cursor: pointer;
        }
    }
`;
