import React, { useState, useRef } from 'react';

export default function UseAudio(url, soundName, sounds) {
    const audioRef = useRef(null);
    const soundRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackStatus, setPlaybackStatus] = useState("play");
    const [isSeeking, setSeeking] = useState(false);
    let soundUrl = '';

    if (!sounds) return [];

    sounds.forEach(function(sound){
        if (sound.name === soundName) {
            soundUrl = sound.audio;
        }
    });

    return [
        <>
            <audio
                src={url}
                hidden
                ref={audioRef}
                onLoadedData={() => {
                    setPlaybackStatus("play");
                    setDuration(audioRef.current.duration);
                    audioRef.current.play();
                }}
                onSeeking={() => setSeeking(true)}
                onSeeked={() => setSeeking(false)}
                onTimeUpdate={() => {
                    setCurrentTime(audioRef.current.currentTime);
                }}
            />
            <audio
                src={soundUrl}
                hidden
                ref={soundRef}
                onLoadedData={() => {
                    setPlaybackStatus("play");
                    if (soundName !== 'None') soundRef.current.play();
                }}
            />
        </>,
        {
            currentTime,
            playbackStatus,
            isSeeking,
            duration,
            setTime: seconds => {
                audioRef.current.currentTime = seconds;
            },
            togglePlaybackStatus: () => {
                // there are nicer ways to handle 'play' and 'pause'. The thing is that Safari
                // browsers only allow you to change audio's playback-state in direct response to an event
                // https://medium.com/@curtisrobinson/how-to-auto-play-audio-in-safari-with-javascript-21d50b0a2765
                if (playbackStatus === "play") {
                    audioRef.current.pause();
                    if (soundName !== 'None') soundRef.current.pause();
                    setPlaybackStatus("pause");
                }
                if (playbackStatus === "pause") {
                    audioRef.current.play();
                    if (soundName !== 'None') soundRef.current.play();
                    setPlaybackStatus("play");
                }
            },
        },
    ];
}