import { useState } from 'react';
import { useCookies } from "react-cookie";

export default function UseSystemMessage() {
    const [cookies, setCookie] = useCookies(['systemMessage']);
    const [showSystemMessage, setShowSystemMessage] = useState(false);

    const cookieExpirationDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365);

    function setSystemMessageCookie(data) {
        const cookieData = {
            id: data.id,
            appLaunchesToShow: parseInt(data.app_launch_to_show),
            currentVisit: 1,
            isDismissed: false,
            timeDismissed: null,
            isDismissedPermanantly: false
        };

        setCookie('systemMessage', cookieData, { path: "/", expires: cookieExpirationDate });
    }

    function updateSystemMessageCookie(data) {
        const cookieData = cookies.systemMessage;
        cookieData.currentVisit = cookies.systemMessage.currentVisit + 1;
        setCookie('systemMessage', cookieData, { path: "/", expires: cookieExpirationDate });
    }

    function dismissMessage(isPermanent) {
        const cookieData = cookies.systemMessage;
        cookieData.timeDismissed = Date.now();
        cookieData.isDismissed = true;
        cookieData.isDismissedPermanantly = !!isPermanent;
        setCookie('systemMessage', cookieData, { path: "/", expires: cookieExpirationDate });
        setShowSystemMessage(false);
    }

    function handleSystemMessage(data) {
        const cookie = cookies.systemMessage;
        if (data) {
            if (!cookie || cookie.id !== data.id) {
                setSystemMessageCookie(data);                  
            } else if (cookie.isDismissedPermanantly) {
                return;       
            } else {        
                // if current visit is equal to or greater than specified launch number, and message has not been dismissed - show message
                // if message is dismissed and current time exceeds timer since dismissal - show message
                if (cookie.currentVisit + 1 >= cookie.appLaunchesToShow && !cookie.isDismissed) {
                    setTimeout(function () {
                        setShowSystemMessage(true);
                    }, 10000); // GS-2025, client wants ease-of-use pop up shows up after 10 sec delay.
                } else if (cookie.isDismissed && Date.now() >= cookie.timeDismissed + parseInt(data.negative_button_hide_rules.milliseconds)) {
                    setTimeout(function () {
                        setShowSystemMessage(true);
                    }, 10000);
                }
                updateSystemMessageCookie(data);
            }
        } else {
            return;
        }
    }

    return { 
        showSystemMessage,
        dismissMessage,
        handleSystemMessage 
    };
}