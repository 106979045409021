import React from 'react';
import { MeditationGridStyled } from './MeditationStyles.js';
import MeditationsCard from './MeditationsCard';
import Carrousel from '../Carrousel';

export default function MeditationGrid(props) {
    const { meditation, sounds } = props;
    return (
        <MeditationGridStyled>
            <div className="show-on-desktop">
                {meditation.slice(0, 5).map((item, i) => {
                    return <MeditationsCard key={item.id} myClass={`grid-item-${i + 1} large`} meditation={item} sounds={sounds} grid={true} />
                })}
            </div>
            <div className="show-on-mobile">
                <Carrousel section="meditations" visibleCount="5" max={meditation.length} showControls="true">
                    {meditation.map((item, i) => {
                        return <MeditationsCard key={item.id} id={`meditations-slide-${i+1}`} visibleCount="4" meditation={item} sounds={sounds} />
                    })}
                </Carrousel>
            </div>
        </MeditationGridStyled>
    );
}