import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const AnnouncerContainer = styled.span`
    &.screenreader-only {
        position: absolute;
        right: 9999999px;
    }
`;

export default function TitleAnnouncer(props) {
    const { title, isAssertive, screenReaderOnly, ariaLabelText = null } = props;
    const [titleText, setTitleText] = useState('');

    {/* 
        for screen reader accessiblity -
        aria-live attribute works more reliably when text changes, versus announcing text originally rendered;
        updating the state after 1/100th second helps ensure the aria-live attribute causes screen readers to read text
    */}

    useEffect(() => {
        setTimeout(() => {
            setTitleText(title);
        }, 10)
    }, []);

    if (title) {
        return (
            <>
                <AnnouncerContainer
                    tabIndex="0"
                    role="text"
                    aria-label={ariaLabelText ?? undefined}
                    aria-live={isAssertive ? "assertive" : "polite"}
                    className={screenReaderOnly ? 'screenreader-only' : ''}
                >
                    {titleText}
                </AnnouncerContainer>
            </>
        )
    } else {
        return <></>;
    }
}