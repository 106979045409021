import React from 'react';
import { RowStyled } from './PageBuilderStyles.js';
import MappedComponent from '../PageBuilder/MappedComponent';
import { SectionStyled } from "../Page/PageStyles";

export default function Row(props) {
    const { data } = props;
    if (data.length) {
        return (
            <SectionStyled>
                <RowStyled>
                    {data.map((item, i) => {
                        return (
                            
                            <MappedComponent key={i} {...props} data={item.properties} />
                                
                        );
                    })}
                </RowStyled>
            </SectionStyled>
        )
    } else {
        return <></>;
    }
}