import React from 'react';
import Cookies from 'universal-cookie';
import { Page } from '../Page';
import Carrousel from '../Carrousel/Carrousel';
import IconCard from '../Cards/IconCard';
import CurrentPageNav from '../../hooks/CurrentPageNav';
import { ErrorPageStyled } from './ErrorStyles';
import { SEO } from "../Page"; 
import { getLanguageParam } from '../../hooks/languageUtils';

const languageParam = getLanguageParam();

const cards = [
    {
       "icon":{
          "light":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-messages.png",
          "dark":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-messages.png"
       },
       "title":null,
       "footer":{
          "value":"Message your doctor",
          "style":{
             "size":"nano",
             "weight":"normal",
             "color":{
                "light":"#1175A7",
                "dark":"#1175A7"
             },
             "orientation":"center",
             "lines":2,
             "auto_resize":true
          }
       },
       "action":{
          "type":"internal_app_link",
          "link":"livewell://epic/messages?meta=eyJwcm9tcHQiOiAiV2hvc2UgYWNjb3VudCB3b3VsZCB5b3UgbGlrZSB0byB1c2U/In0=",
          "navigation":"modal",
          "web_redirect":"https://livewell.aah.org/Chart/app/communication-center/ask-question" + languageParam
       }
    },
    {
       "icon":{
          "light":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-flask.png",
          "dark":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-flask.png"
       },
       "title":null,
       "footer":{
          "value":"Get your test results",
          "style":{
             "size":"nano",
             "weight":"normal",
             "color":{
                "light":"#1175A7",
                "dark":"#1175A7"
             },
             "orientation":"center",
             "lines":2,
             "auto_resize":true
          }
       },
       "action":{
          "type":"internal_app_link",
          "link":"livewell://epic/labs?meta=eyJwcm9tcHQiOiAiV2hvc2UgYWNjb3VudCB3b3VsZCB5b3UgbGlrZSB0byB1c2U/In0=",
          "navigation":"modal",
          "web_redirect":"https://livewell.aah.org/Chart/Clinical/TestResults" + languageParam
       }
    },
    {
       "icon":{
          "light":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-calendar.png",
          "dark":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-calendar.png"
       },
       "title":null,
       "footer":{
          "value":"Manage appointments",
          "style":{
             "size":"nano",
             "weight":"normal",
             "color":{
                "light":"#1175A7",
                "dark":"#1175A7"
             },
             "orientation":"center",
             "lines":2,
             "auto_resize":true
          }
       },
       "action":{
          "type":"internal_app_link",
          "link":"livewell://epic/appointments?meta=eyJwcm9tcHQiOiAiV2hvc2UgYXBwb2ludG1lbnRzIHdvdWxkIHlvdSBsaWtlIHRvIHZpZXc/In0=",
          "navigation":"modal",
          "web_redirect":"https://livewell.aah.org/Chart/Visits" + languageParam
       }
    },
    {
       "icon":{
          "light":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-video.png",
          "dark":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-video.png"
       },
       "title":null,
       "footer":{
          "value":"Start a video visit",
          "style":{
             "size":"nano",
             "weight":"normal",
             "color":{
                "light":"#1175A7",
                "dark":"#1175A7"
             },
             "orientation":"center",
             "lines":2,
             "auto_resize":true
          }
       },
       "action":{
          "type":"internal_app_link",
          "link":"livewell://epic/ondemandtelehealth?meta=eyJwcm9tcHQiOiAiV2hvc2UgYWNjb3VudCB3b3VsZCB5b3UgbGlrZSB0byB1c2U/In0=",
          "navigation":"modal",
          "web_redirect":"https://livewell.aah.org/Chart/Scheduling?workflow=OnDemandTelehealth" + languageParam
       }
    },
    {
       "icon":{
          "light":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-credit_card.png",
          "dark":"https://enterpriseone-dev.s3.us-east-2.amazonaws.com/1639589785-credit_card.png"
       },
       "title":null,
       "footer":{
          "value":"Manage billing",
          "style":{
             "size":"nano",
             "weight":"normal",
             "color":{
                "light":"#1175A7",
                "dark":"#1175A7"
             },
             "orientation":"center",
             "lines":2,
             "auto_resize":true
          }
       },
       "action":{
          "type":"internal_app_link",
          "link":"livewell://epic/billing/summary?meta=eyJwcm9tcHQiOiAiV2hvc2UgYWNjb3VudCB3b3VsZCB5b3UgbGlrZSB0byB1c2U/In0=",
          "navigation":"modal",
          "web_redirect":"https://livewell.aah.org/Chart/Billing/Summary" + languageParam
       }
    }
]

export default function ErrorPage(props) {
    const { httpCode } = props;
    let title, description;
    switch(httpCode.toString()) {
        case '500':
            title = "We're sorry, an unexpected error has occurred.";
            description = "We are working on fixing the problem.";
            break;
        case '404':
            title = "We're sorry, this page is missing.";
            description = "But we're right here to help.";
            break;
        default:
            title = "We're sorry, an unexpected error has occurred.";
            description = "We are working on fixing the problem.";
    }
    return (
       <>
         <SEO title="Page Not Found | LiveWell | Advocate Aurora Health"/>               
         <Page>
               <CurrentPageNav title={httpCode}/>
               <ErrorPageStyled>
                  <div className="page-content">
                     <h2 className="title">{title}</h2>
                     <h3 className="description">{description}</h3>
                  </div>
                  <Carrousel visibleCount="5" max={cards} showControls="true">
                     {cards.map((item, i) => {
                           return <IconCard key={i} visibleCount="5" item={item} />

                     })}
                  </Carrousel>
               </ErrorPageStyled>
         </Page>
        </>
    )
}