import React, { useRef } from 'react';
import Carrousel from '../Carrousel';
import { ManualImageScrollerCard } from '../Cards';

export default function ManualImageScroller(props) {
    const { data } = props;
    const url = useRef('');    
    return ((data.items.length)
        ?   
        <Carrousel visibleCount="1" showIndicator="true" max={data.items.length} scroll_time={data.scroll_time} type="manual-image-scroller">
                {
                    data.items.map((item, i) => {
                        return <ManualImageScrollerCard key={i} data={item} />
                    })
                }
        </Carrousel>
        :   <></>
    );
}