import React, { useState, useEffect, useContext } from 'react';
import { Page } from '../Page';
import CurrentPageNav from '../../hooks/CurrentPageNav';
import { MappedComponent } from '../PageBuilder';
import { postAnalytics, getWellnessData } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { SEO } from "../Page";
import ErrorPage from "../Error/ErrorPage";
import LoadingIndicator from '../Loading/LoadingIndicator';

export default function Wellness(props) {
    const [wellnessData, setWellnessData] = useState();
    const { token } = useContext(GlobalContext);
    const [apiStatus, setApiStatus] = useState({success: true, httpCode: ""});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (token) {
            setData();
        }

        async function setData() {
            try {
                setIsLoading(true);
                const data = await getWellnessData(token);
                setIsLoading(false);
                data.title = "Wellness";
                setWellnessData(data);
                postAnalytics(token, {
                    "event_name": data.analytic_name_prefix + "_view",
                    "event_data": {
                        "page_id": data.analytic_additional_params.page_id
                    }
                });
            } catch (error) {
                setIsLoading(false);
                setApiStatus({
                    success: false,
                    httpCode: error.http_code ? error.http_code : "500"
                });
            }
        }   
    }, [token]);    

    return (
        <>
            <SEO title="Wellness | LiveWell | Advocate Aurora Health"
                 description="Manage your physical and mental wellness with LiveWell's wellness features. Enjoy relaxing
                 guided meditation, health quizzes, and recipes." />
            {apiStatus.success && !isLoading && <Page>                
                <CurrentPageNav {...props}/>
                {wellnessData && wellnessData.content.map((item) => {
                    return <MappedComponent 
                        analytic_name_prefix={wellnessData.analytic_name_prefix} 
                        page_id={wellnessData.analytic_additional_params.page_id} 
                        type={item.type} 
                        data={item} 
                        token={token} 
                        title={wellnessData.title} 
                        pageState={null} />
                })}
            </Page>}
            
            {!apiStatus.success && <ErrorPage httpCode={apiStatus.httpCode} />}

            {isLoading && 
                <Page>
                    <LoadingIndicator />
                </Page>            
            }           
        </>
    );
}