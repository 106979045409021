import { useState } from "react";
import { useCookies } from "react-cookie";
import { getAuth } from "../common/api";

export default function UseAuth() {
    const [cookies, setCookie] = useCookies(['authToken']);
    const [token, setToken] = useState(cookies.authToken);   

    async function fetchAuth() {
        const res = await getAuth();
        const newToken = res.auth_token;
        setToken(newToken);
        setCookie('authToken', newToken, { path: "/", secure:true}); //TODO - Consider removing the auth token if it is not needed
    }

    return { token, fetchAuth };
}