import React from 'react';
import { PrimitiveButtonStyled } from './PageBuilderStyles';
import { getClickableLink } from "../../lib/util";

export default function PrimitiveButton(props) {
    const { data } = props;
    const value = data.button?.text?.value;
    
     // personalization feature not implemented for website, hide button if feature on
    const isPersonalization = data.button?.text?.value.toLowerCase().includes('personaliz');
    if (isPersonalization) return <></>;
   
    return (
        <PrimitiveButtonStyled {...props}>
            <a href={getClickableLink(data.button?.action?.url)} target="_blank" rel="noreferrer" role="button" aria-label={value + ". Link opens in a new tab"}><div className="text">{value}</div></a>
        </PrimitiveButtonStyled>
    );
}