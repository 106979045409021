// import {getUserLocales} from 'get-user-locale';

// const getUserPreferredLanguageWithoutRegion = () => {
//     const userLocale = getUserLocales()[0];
//     return userLocale.split('-')[0];
// };

export const APIHEADER = {
    'Content-Type': 'application/json',
    'Epic-Authenticated': false,
    'api_key': process.env.REACT_APP_API_KEY,
    'Draft-State': false,
    'access-control-allow-origin' : '*',
    'Time-Zone': 'EST',
    'Client-Name': 'livewell',
    'Accept-Language': 'en',
};
