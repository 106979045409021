import React, { useEffect, useRef, useContext, useState } from 'react';
import { VerticalCardStyled } from './CardStyles.js';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { ColorizedImage } from '../Page';
import { getClickableLink, setLinkFromApiAction, setAriaLabelForButton } from '../../lib/util';

export default function VerticalCard(props) {
    const { analytic_name_prefix, page_id, action, data } = props;
    const card = useRef();
    const { token } = useContext(GlobalContext);
    const icon = data.image;
    const tintColor = data.image?.tint_color?.light;
    const canvasSizes = {
        // needed in case icons need colorized
        small: 25,
        medium: 30,
        large: 60
    }
    const link = setLinkFromApiAction(action);
    let stateSelector;
    if (props.stateSelector) {
        [stateSelector] = props.stateSelector;
    }

    useEffect(() => {
        if (action.navigation === "push") {
            card.current.onclick = (stateSelector) ? () => {stateSelector.openModal(token, link)} : null;
            card.current.onkeydown = (stateSelector) ? handlePressEnter : null;
        }
    }, []);

    // ensure modal opens when user presses enter when card is focused
    function handlePressEnter(e) {        
        if (e.keyCode === 13) {
            stateSelector.openModal(token, link);
        }
    }

    return (
        <VerticalCardStyled 
            ref={card} 
            className={(action.type === "internal_app_link") ? 'pointer' : ''}
            role="button"
            aria-label={setAriaLabelForButton(data.title, data.message, action.type !== "internal_app_link")}
            tabIndex="0"
            onClick={() => postAnalytics(token, {
                "event_name": analytic_name_prefix + "_tap",
                "event_data": {"page_id": page_id, ...action}
            })}>
            <div className="card-container">
                {!tintColor ? 
                    <img src={data.image.light} alt="icon" title="Icon" aria-hidden="true" /> :                    
                    <ColorizedImage imageSrc={icon.light} colorHex={icon.tint_color.light} canvasSizes={canvasSizes} aria-hidden="true" />
                }
                <div className="text">
                    {action.type === "internal_app_link" && !action.web_redirect && data.title}
                    {(action.type !== "internal_app_link" || action.web_redirect) &&
                        <a href={getClickableLink(link)} target="_blank" rel="noreferrer" aria-label={data.title + ". Link opens in a new tab"}>
                            {data.title}
                        </a>
                    }
                </div>
                <div className="description">{data.message}</div>
            </div>
        </VerticalCardStyled>
    );
}