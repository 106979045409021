import React, { useRef } from 'react';
import { ManualImageScrollerCardStyled } from './CardStyles';
import { getClickableLink, setLinkFromApiAction } from '../../lib/util';

export default function ManualImageScrollerCard(props) {
    const { type, image, title, description, action } = props.data;
    const gridType = useRef();
    gridType.current = (props.data.type === "right_image") ? 'right-image' : 'left-image';
    const link = setLinkFromApiAction(action);
    const clickableLink = getClickableLink(link);

    function handlePressEnter(e) {        
        if (e.key === "Enter") {
            window.open(clickableLink);
        }
    }

    return (
        <ManualImageScrollerCardStyled className="manual-image-scroller-container" tabIndex="0" onKeyDown={handlePressEnter}>
            {type === "legacy"
                ? <a href={clickableLink} rel="noreferrer" target="_blank"
                     aria-label={image.alt + ". Link opens in a new tab"} tabIndex="-1">
                    <div>
                        <img className="legacy-image" src={image.light} alt={image.alt || "hero"}
                             title="LiveWell hero image"/>
                    </div>
                </a>
                : <div className={`manual-image-scroller-grid ${gridType.current}`}>
                    <div className="image">
                        <img src={image.light} alt={image.alt || "hero"} title="hero"/>
                    </div>
                    <div className="title">
                        <a href={clickableLink} target="_blank" rel="noreferrer"
                           aria-label={title.value + ". Link opens in a new tab"} tabIndex="-1">
                            <span className="title-text">{title.value}</span>
                        </a>
                    </div>
                    <div className="description">
                        <span className="description-text">{description && description.value}</span>
                    </div>
                </div>
            }
        </ManualImageScrollerCardStyled>
    );
}