import Cookies from 'universal-cookie';

export function toggleLanguage(currentLanguageText, setToggleLanguageText, setLanguageCode, setCookie) {
    let newLanguage = 'Español';
    let newLanguageCode = 'en';
    if (currentLanguageText === 'Español') {
        newLanguage = 'English';
        newLanguageCode = 'es';
    }
    
    setToggleLanguageText(newLanguage);
    setLanguageCode(newLanguageCode);
    
    const expirationDate = new Date(Date.now() + (1000 * 60 * 60 * 24 * 15));
    setCookie('selectedLanguage', newLanguage, { path: "/", expires: expirationDate }); 
    setCookie('languageCode', newLanguageCode, { path: "/", expires: expirationDate }); 
    
    window.location.reload();
}

export function getLanguageSubdomain() {
    const cookies = new Cookies();
    const languageCode = cookies.get('languageCode');
    if (!languageCode || languageCode === 'en') {
        return 'www';
    } else {
        return languageCode;
    }
}

export function getLanguageParam(currentLanguageText, setToggleLanguageText, setLanguageCode, setCookie) {
    const cookies = new Cookies();
    const languageCode = cookies.get('languageCode');
    if (languageCode === 'es') {
       return '?lang=es-US';
    }
    return '';
}