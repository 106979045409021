import styled from 'styled-components';

export const FooterStyled = styled.div`
    max-width: 1068px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    position: relative;
    padding: 0 1rem 2rem 1rem;
    font-size: 1.125rem;

    .nav-items {
        grid-row: 2 / 3;
        margin-bottom: 2rem;
        .nav-item {
            display: block;
            width: 33%;
            margin-top: 1.5rem;
            line-height: 1.375rem;
        }
        a {
            font-weight: 600;
        }
    }

    .logo {
        grid-row: 1 / 2;
        height: 161.2px;
    }

    .info-links {
        grid-row: 3 / 4;
        height: auto;
    }

    .info-text {
        grid-row: 4 / 5;
    }

    .copyright {
        grid-row: 5;
    }

    .info-link-title {
        font-weight: 600;        
        line-height: 2.0625rem;
        letter-spacing: -0.0225rem;
    }

    .info-link {
        display: block;
        line-height: 2.5rem;        
    }

    .vertical-bar {
        display: none;
    }

    .vertical-bar-mobile { 
        color: #888888;
        display: inline-block;
    }

    .inline-mobile {
        display: inline-block;
    }

    .light-gray {
        color: #6F6F6F;
    }

    .dark-gray {
        color: #707070;
    }

    .small-margin-top {
        margin-top: 1rem;
    }
    
    @media (max-width: 768px) {
        .small-margin-top-mobile {
            margin-top: 1rem;
            display: inline-block;
        }
    }

    @media (min-width: 769px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 5rem 1fr;
        align-items: start;

        .logo {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .nav-items {
            grid-column: 2;
            grid-row: 1;
            height: auto;
            margin: 0 0 0 3rem;
            .nav-item {
                display: inline-block;
                width: auto;
                text-align: left;
                margin-top: 3.2725rem;
                margin-right: 1.2rem;
            }
        }
        
        .info-links {
            grid-column: 2;
            grid-row: 2 / 3;
            width: 25rem;
            margin: 1.5rem 0 0 3rem;
            .info-link {
                display: inline-block;
                @media (min-width: 769px) {
                    &.pp, &.tou {
                        display: block
                    }
                    &.tou {
                        margin-bottom: 1rem;
                    }
                }
            }
            a {
                font-weight: 400;
            }
        }

        .copyright {            
            grid-column: 1 / 2;
            grid-row: 2 / 4;
            margin-top: 7rem;
        }

        .vertical-bar { 
            color: #888888;
            display: inline;
        }
    }

    @media (min-width: 1025px) {
        grid-template-columns: 60% 40%;
        grid-template-rows: 1fr 1fr 1fr 1fr;

        .logo {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
        }

        .nav-items {
            grid-column: 2;
            grid-row: 1 / 2;
            margin: 0;
        }
        
        .info-links {
            grid-column: 2;
            grid-row: 2 / 5;
            margin: 1.5rem 0 0 0;
        }

        .copyright {            
            grid-column: 1 / 2;
            grid-row: 4;
            margin-top: 0;
        }
    }
`;

export const VersionContainer = styled.div`
    width: 100%;
    padding: 1rem;
    color: #707070;
`;