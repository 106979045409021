import styled from 'styled-components';

export const ColumnStyled = styled.div`
    display: block;

    .flex {
        @media (min-width: 769px) {
            display: flex;
        }
    }
`;

export const RowStyled = styled.div`
    display: flex;
    margin-top: 0;
`;

export const SpanningGridStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .934rem;
    margin-bottom: 40px;

    @media (min-width: 769px) {
        gap: 2rem;

        &.three-columns {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
`;

export const PromoStyled = styled.div`
    display: grid;
    grid-template-columns: 25% 75%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.161);
    border-radius: 8px;
    margin-top: 2.6rem;

    @media (min-width: 769px) {
        grid-template-columns: 52% 48%;
        box-shadow: none;
        background-color: #F8F7F6;
        margin-top: 5.4rem;
    }

    .grid-item-image {

        @media (min-width: 769px) {
            grid-column: 2;
            grid-row: 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px 0 0 8px;
        }
    }

    .grid-item-text {
        padding: 1rem;

        @media (min-width: 769px) {
            padding: 2rem;
            margin-top: 1.25rem;
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .title {
            font-size: 1.125rem;
            line-height: 1.125rem;
            color: #535353;
            font-weight: bold;
            margin: 0;
            
            @media (min-width: 769px) {
                font-weight: 600;
                font-size: 1.850rem;
                line-height: 2.75rem;
                margin-bottom: 1.375rem;
            }
        }

        .description {
            margin-top: .25rem;
            font-size: 0.75rem;
            line-height: 0.9375rem;
            color: #707070;

            @media (min-width: 769px) {
                font-size: 1.125rem;
                line-height: 1.75em;
                margin-bottom: 1.375rem;
                margin-top: 2rem;
            }
        }

        .link {
            font-weight: bold;
            font-size: 0.75rem;
            line-height: 0.75rem;
            color: #117BB0;

            @media (min-width: 769px) {
                margin-top: 2rem;
                display: inline-block;
                padding: 0 1rem;
                text-align: center;
                background: #117BB0;
                border-radius: 3px;
                font-weight: bold;
                font-size: 1rem;
                line-height: 3rem;
                color: #FFFFFF;
                width: auto;
            }
        }
    }
`;

export const PrimitiveImageStyled = styled.div`
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const PrimitiveButtonStyled = styled.div`
    display: inline-block;
    width: 95%;
    background-color: ${props => props.data?.background?.color?.light};
    border-radius: 8px;
    height: 5rem;
    margin: .5rem 0;
    position: relative;

    @media (min-width: 1025px) {
        width: 30%;
        height: auto;
        aspect-ratio: 1 / 1;
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        color: ${props => props.data?.button?.text?.style?.color?.light};   
    }

    .text {
        color: ${props => props.data?.button?.text?.style?.color?.light};
        width: 100%;
        font-weight: 700;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0 1rem;
    }
`;