import React, { useContext } from 'react';
import { EmergencyAlertStyled } from './HomeStyles.js';
import GlobalContext from '../../GlobalContext';
import { getClickableLink } from '../../lib/util';

export default function EmergencyAlert(props) {
    const { emergencyAlert } = useContext(GlobalContext);
    const [alertData] = emergencyAlert; 
    const { link } = props;

    function closeAlert() {
        alertData.closeAlert();
    }

    return (
        <EmergencyAlertStyled id="alert-container">
            <h4>{props.heading}</h4>
            <p>{props.text}</p>
            {link && <a href={getClickableLink(props.link)} target="_blank" rel="noreferrer"aria-label={props.linkText + ". Link opens in a new tab"}>
                {props.linkText}
            </a>}
            
            <span onClick={closeAlert} className="close-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                    <path d="M11.4653 23.3467C17.7319 23.3467 22.9204 18.147 22.9204 11.8916C22.9204 5.625 17.7207 0.436523 11.4541 0.436523C5.19873 0.436523 0.0102539 5.625 0.0102539 11.8916C0.0102539 18.147 5.20996 23.3467 11.4653 23.3467ZM8.01758 17.2036C7.52344 17.2036 7.15283 16.8555 7.15283 16.395C7.15283 16.1592 7.20898 16.002 7.35498 15.7886L10.2974 11.7231L7.52344 7.90479C7.33252 7.64648 7.27637 7.48926 7.27637 7.23096C7.27637 6.75928 7.69189 6.36621 8.18604 6.36621C8.5791 6.36621 8.80371 6.51221 9.07324 6.88281L11.5327 10.4541H11.5889L14.0146 6.89404C14.2617 6.51221 14.4976 6.36621 14.8569 6.36621C15.3511 6.36621 15.7329 6.72559 15.7329 7.18604C15.7329 7.39941 15.6768 7.59033 15.5083 7.80371L12.5884 11.7793L15.4858 15.7437C15.6431 15.9458 15.7104 16.1143 15.7104 16.3501C15.7104 16.8555 15.3174 17.2036 14.7896 17.2036C14.4302 17.2036 14.2168 17.0688 13.9248 16.6646L11.4316 13.1045H11.3867L8.88232 16.6646C8.5791 17.0688 8.38818 17.2036 8.01758 17.2036Z" fill="#1175A7"/>
                </svg>
            </span>

        </EmergencyAlertStyled>        
    );
}