const enTranslations = {
  // CurrentPageNav.js
  home: "Home",

  // Footer.js
  faq: "FAQ",
  livewell_privacy_policy: "LiveWell Privacy Policy",
  livewell_terms_of_use: "LiveWell Terms of Use",
  notice_of_privacy_practices: "Notice of Privacy Practices",
  notice_of_non_discrimination: "Notice of Nondiscrimination",
 
  // Meditations.js
  guided_meditations: "Guided Meditations",
  guided_meditations_description: "Find calm in your busy day with our guided meditations. Practice anytime, anywhere to ease stress, improve sleep and increase mindfulness.", 
  
  // MeditationsFeatured.js
  see_more: "See more",
  
  // MyChartModal.js
  mychart_modal_blurb: "Sign in below or close this window to explore LiveWell as a guest.",
  make_a_secure_online_payment: "Make a secure online payment",
  no_account_or_sign_in: "- no account or sign-in required.",

  // Nav.js
  wellness: "Wellness",
  mychart: "MyChart",
  find_care: "Find Care",
  health_e_news_articles: "Health eNews Articles",
  recipes: "Recipes",
  meditations: "Meditations",

  // PageSection.js
  show_all: "Show all",

  // SignInButton.js
  sign_in: "Sign in",
  to: "to",
};

export default enTranslations;
