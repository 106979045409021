import React from 'react';
import { PromoStyled } from './PageBuilderStyles.js';
import { setLinkFromApiAction } from '../../lib/util';

export default function Promo(props) {
    const data = props.data;
    const buttonText = data.button?.text?.value;
    const link = setLinkFromApiAction(data.button?.action);
    
    return (
        <PromoStyled>
            <div className="grid-item-image">
                <img src={data.image?.light} alt={data.image?.alt} title="Promo image" />
            </div>
            <div className="grid-item-text">
                <h3 className="title" dangerouslySetInnerHTML={{ __html: `${data.text?.value}` }}></h3>
                <div className="description">{data.content?.value}</div>
                <a className="link" href={link} target="_blank" rel="noreferrer" aria-label={buttonText + ". Link opens in a new tab"}>
                    {buttonText}
                </a>
            </div>
        </PromoStyled>
    );
}