export const setLinkFromApiAction = (action) => {
    let link = '';
    if (action.web_redirect) {
        link = getClickableLink(action.web_redirect);
    } else if (action.type === 'web' && action.url) {
        link = getClickableLink(action.url);
    } else if (action.type === 'internal_app_link' && action.link) {
        link = action.link;
    }
    return link;
}

export const getClickableLink = link => {
    if (!link) return null;
    return link.startsWith("http://") || link.startsWith("https://") ?
        link
        : `https://${link}`;
};

export const setAriaLabelForButton = (title, description, isExternalSite) => {
    let result = title;
    if (isExternalSite) {
        result +=  '. Link opens in a new tab';
    } else {
        result +=  '. Button opens pop-up dialog'
    }
    result += `. ${description}`;
    return result;
}
