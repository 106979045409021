import React from 'react';
import { Column, IconCards, GraphicTextContentCards, MultiBlogFeedCards, MeditationsFeatured, SpanningGrid, ManualImageScroller, Promo, PrimitiveImage, PrimitiveText, PrimitiveButton, Row } from './';
import { HorizontalCard, VerticalCard } from "../Cards";
import ErrorBoundary from '../Error/ErrorBoundary';

export default function MappedComponent(props) {
    const type = props.data.type;
    switch (type) {
        case "icon_cards":
            return (
                <ErrorBoundary>
                    <IconCards {...props} />
                </ErrorBoundary>
            );
        case "graphic_text_content_cards":
            return (
                <ErrorBoundary>
                    <GraphicTextContentCards {...props} carouselView={true} />
                </ErrorBoundary>
            );
        case "multi_blog_feed_cards":
            return (
                <ErrorBoundary>
                    <MultiBlogFeedCards {...props} />
                </ErrorBoundary>
            );
        case "meditations":
            return (
                <ErrorBoundary>
                    <MeditationsFeatured {...props} />
                </ErrorBoundary>
            );
        case "spanning_grid":
            return (
                <ErrorBoundary>
                    <SpanningGrid {...props} />
                </ErrorBoundary>
            );
        case "manual_image_scroller":
        case "manual_image_scroller_2":
            return (
                <ErrorBoundary>
                    <ManualImageScroller {...props} />
                </ErrorBoundary>
            );
        case "column":
            return (
                <ErrorBoundary>
                    <Column {...props} data={props.data.children} type={type} />
                </ErrorBoundary>
            );
        case "row":
            return (
                <ErrorBoundary>
                    <Row {...props} data={props.data.children} type={type} />
                </ErrorBoundary>
            );
        case "card_horizontal":
            return (
                <ErrorBoundary>
                    <HorizontalCard {...props} />
                </ErrorBoundary>
            );
        case "card_vertical":
            return (
                <ErrorBoundary>
                    <VerticalCard {...props} />
                </ErrorBoundary>
            );
        case "promo_card":
            return (
                <ErrorBoundary>
                    <Promo {...props} />
                </ErrorBoundary>
            );
        case "primitive_image":
            return (
                <ErrorBoundary>
                    <PrimitiveImage {...props} />
                </ErrorBoundary>
            );
        case "primitive_text":
            return (
                <ErrorBoundary>
                    <PrimitiveText {...props} />
                </ErrorBoundary>
            );
        case "primitive_button":
            return (
                <ErrorBoundary>
                    <PrimitiveButton {...props} />
                </ErrorBoundary>
            );
        default:
            return <></>;
    }
}