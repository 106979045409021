import styled from 'styled-components';

export const MeditationGridStyled = styled.div`    
    .show-on-desktop {
        display: none;
        @media (min-width: 1025px) {
            display: grid;
            grid-template-columns: 50% 1fr 1fr;
            gap: 1rem;

            .grid-item-1 {
                grid-column: 1 / 2;
                grid-row: 1 / 3;
            }

            .grid-item-2 {
                width: 100%;
                grid-column: 2 / 3;
                grid-row: 1 / 2;      
            }

            .grid-item-3 {
                grid-column: 3;
                grid-row: 1 / 2;
            }
                

            .grid-item-4 {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
                
            }

            .grid-item-5 {
                grid-column: 3;
                grid-row: 2 / 3;
            }
        }
    }

    .show-on-mobile {
        display: block;
        @media (min-width: 1025px) {
            display: none;
        }
    }
`;

export const HeaderStyled = styled.h1`
    font-weight: bold;
    font-size: 30px;
    color: #535353;
    @media only screen and (min-width: 481px) {
        font-family: Montserrat;
        font-size: 40px;
        letter-spacing: 1.32px;
    }
`;

export const Summary = styled.div`
    padding-bottom: 4px;
    @media only screen and (min-width: 481px) {
        width: 70%;
        padding-bottom: 16px;
    }
`;

export const ImageGrid = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 2rem;
    
    @media (min-width: 481px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 769px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;


export const MeditationCardStyled = styled.button`    
    flex-shrink: 0;    
    position: relative;
    width: 35%;
    margin-right: 2rem;
    padding: 0;
    border: none;
    background: #FFFFFF;
    color: #535353;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    
    &.show-all {
        margin: 0;
        width: auto;
        height: auto;
    }

    @media only screen and (min-width: 481px) {
        width: auto;
    }

    img {
        border-radius: 8px;
        width: 100%;
        height: auto;
        max-height: 263px;
        object-fit: cover;
        vertical-align: bottom;
        aspect-ratio: 1 / 1;
    }
    .subtext-container {
        position: absolute;
        top: 0;
        height: 100%;
        border-radius: 8px;
        background: linear-gradient(360deg, #000 0%, rgba(84, 84, 84, 0.0001) 60%);
        width: 100%;
    }
    .subtext {
        bottom: 16px;
        position: absolute;
        margin-left: .75rem;
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 600;
        color: white;
        @media (min-width: 481px) {
            bottom: 32px;
            font-size: 14px;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    &.max-visible-2 {
        width: calc(80% - .5rem);
        @media (min-width: 481px) {
            width: calc(50% - .5rem);
        } 
    }

    &.max-visible-3 {
        width: calc(80% - .5rem);
        @media (min-width: 481px) {
            width: calc(50% - .5rem);
        }
        @media (min-width: 769px) {
            width: calc(33% - .5rem);
        }
    }

    &.max-visible-4 {
        width: calc(80% - .5rem);
        @media (min-width: 481px) {
            width: 40%;
        }
        @media (min-width: 769px) {
            width: calc(25% - 1.5rem);
        }
        @media (min-width: 1200px) {
            width: calc(25% - 3.8rem);
        }
    }
`;

export const MeditationCardStyledAlt = styled(MeditationCardStyled)`
    margin-bottom: 32px; 
    width: 45%;
    
    img {
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }


    @media only screen and (min-width: 481px) {
        width: auto;
        margin-right: 2rem;
    }
`

export const Modal = styled.div`
    z-index: 99999999999999;
    background: rgba(0, 0, 0, 0.5);
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #535353;
    overflow: scroll;
    height: 100vh;
    .modal-container {
        width: 90%;
        max-width: 660px;
        overflow: hidden !important;
        background: white;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 25px;
        border-radius: 8px;
        @media only screen and (min-width: 481px) and (min-height: 700px) {
            top: 50%;
            margin-top: 0;
            transform: translate(-50%, -50%);
        }
    }
    img {
        position: absolute;
        left: 0;
        z-index: -9;
        height: 700px;
        width: 100%; 
        object-fit: cover;
    }
    .image-show {
        position: relative;
    }
    .copyright {
        z-index: -9;
        position: absolute;
        bottom: 0;
        height: 52px;
        width: 100%;
        padding: 16px 0;
        background: rgba(0, 0, 0, 0.501961);
        border-radius: 0px 0px 8px 8px;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #C9C9C9;
    }
    .modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 15px;
        border-radius: 50%;
        padding: 0.5em;
        width: 30px;
        height: 30px;
        border: 2px solid white;
        background-color: white;
        cursor: pointer;
    }
    .modal-close::before {
        content: " ";
        position: absolute;
        display: block;
        background-color: ${props => props.themeColor};
        width: 2px;
        left: 12px;
        top: 5px;
        bottom: 5px;
        transform: rotate(45deg);
    }
    .modal-close::after {
        content: " ";
        position: absolute;
        display: block;
        background-color: ${props => props.themeColor};
        height: 2px;
        top:12px;
        left: 5px;
        right: 5px;
        transform: rotate(45deg);
    }
    .modal-controls {
        margin-top: 150px;
        background: white;
        overflow-y: scroll;
        @media only screen and (min-width: 769px) {
            overflow-y: hidden;
            width: 100%;
        }
    }
    .modal-title {
        padding-top: 32px;
        font-weight: bold;
        font-size: 24px;
        color: #535353;
    }
    .modal-description {
        font-family: Montserrat;
        font-size: 20px;
        line-height: 28px;
        padding: 24px 16px;
        text-align: left;
        @media only screen and (min-width: 769px) {
            padding: 24px 64px;
        }
    }
    .modal-sounds {
        display: flex;
        flex-wrap: wrap;
        padding: 24px 32px;
        flex-shrink: 0;
        column-gap: 1rem;
        button {
            width: calc(33% - .61rem);
            height: 27px;
            border: 1px solid ${props => props.themeColor};
            font-size: 12px;
            text-align: center;
            color: ${props => props.themeColor};
            background: white;
            border-radius: 30px;
            margin: 0 0 15px 0;
            cursor: pointer;
            @media only screen and (min-width: 769px) {
                width: 90px;
                margin: 8px 1% 0;
            }
        }
        .selectedSound {
            color: white;
            background: ${props => props.themeColor};
        }
        @media only screen and (min-width: 769px) {
            padding: 32px 64px;
            justify-content: space-between;
            min-height: 160px;
            button {
                margin: 8px 0 0;
            }
        }
    }
    .modal-start {
        margin-bottom: 60px;
        background: ${props => props.themeColor};
        border-radius: 10px;
        color: white;
        width: 90%;
        height: 66px;
        font-size: 20px;
        line-height: 19px;
        border: none;
        cursor: pointer;
        @media only screen and (min-width: 769px) {
            width: 50%;
        }
    }
    .screenreader-only {
        position: absolute;
        right: 9999999px;
    }
    svg {
        position: fixed;
        left: 50%;
        top: 35%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
    .modal-timer {
        position: fixed;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: ${props => props.themeColor};
        font-weight: bold;
        font-size: 24px;
        background: rgba(255,255,255, .61);
        border-radius: 27px;
        min-width: 15%;
        padding: 10px;
    }
    .play-button-container {
        position: absolute;
        border: 2px solid red;
        left: 50%;
        top: 35%;
        transform: translate(-50%, -50%);
    }
`;

export const MeditationGridStyledAlt = styled.div`
    width: 7.5rem;
    height: 7.5rem;
    position: relative;
    background: linear-gradient(rgba(84, 84, 84, 0), rgba(0, 0, 0, 1));
    border-radius: 8px;
    flex-shrink: 0;
    margin-right: 1rem;
    cursor: pointer;
    
    img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: fill;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }
    
    .subtext-container {
        position: absolute;
        top: 0;
        height: 100%;
        border-radius: 8px;
        background: linear-gradient(360deg, #000 0%, rgba(84, 84, 84, 0.0001) 60%);
        width: 100%;
    }
    .subtext {
        bottom: 16px;
        position: absolute;
        margin-left: .75rem;
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 600;
        color: white;
        @media (min-width: 481px) {
            bottom: 32px;
            font-size: 1.125rem;
        }
    }

    &.large {
        width: 100%;
        height: auto;
    }
`;
