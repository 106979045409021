import React from 'react';
import { ColumnStyled } from './PageBuilderStyles.js';
import PageSection from '../Page/PageSection';
import MappedComponent from '../PageBuilder/MappedComponent';
import { SectionStyled } from "../Page/PageStyles";

export default function Column(props) {
    const { data } = props;
    if (data.length) {
        return (
            <SectionStyled>
                <ColumnStyled>
                    {data.map((item, i) => {
                        return (
                            <MappedComponent key={i} {...props} action={item.action} data={item.properties} padding={item.padding} />
                        );
                    })}
                </ColumnStyled>
            </SectionStyled>
        )
    } else {
        return <></>;
    }
}