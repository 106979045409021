import React from 'react';
import { Link } from 'react-router-dom';
import { FooterStyled, VersionContainer } from './FooterStyles.js';
import { version } from '../../common/version';
import LogoWithText from '../Page/LogoWithText';
import i18n from '../../i18n.js';
import { getLanguageParam, getLanguageSubdomain } from '../../hooks/languageUtils';

export default function Footer() {
    const languageParam = getLanguageParam();
    const languageSubdomain = getLanguageSubdomain();

    return (
        <footer style={{backgroundColor: "#F8F7F6"}}>
            <FooterStyled>
                <div className="grid-item logo">
                        <LogoWithText />
                </div>
                <div className="grid-item copyright light-gray small-margin-top">&copy; { new Date().getFullYear() } Advocate Aurora Health</div>
                <div className="grid-item nav-items">
                    <span className="nav-item">
                        <a href={"https://livewell.aah.org/chart/Authentication/Login" + languageParam} target="_blank" rel="noreferrer"
                            aria-label="MyChart. Link opens in a new tab">{i18n.t('mychart')}</a>
                    </span>
                    <span className="nav-item"><Link to="/wellness">{i18n.t('wellness')}</Link></span>
                    <span className="nav-item"><Link to="/find-health-care">{i18n.t('find_care')}</Link></span>
                </div>                
                <div className="grid-item info-links">
                    <span className="info-link pp">
                        <a href={"https://" + languageSubdomain + ".advocateaurorahealth.org/livewell/privacy"} target="_blank" rel="noreferrer" 
                            aria-label="LiveWell Privacy Policy. Link opens in a new tab">{i18n.t('livewell_privacy_policy')}</a>
                    </span>
                    <span className="info-link tou">
                        <a href={"https://" + languageSubdomain + ".advocateaurorahealth.org/livewell/terms"} target="_blank" rel="noreferrer" 
                            aria-label="LiveWell Terms of Use. Link opens in a new tab">{i18n.t('livewell_terms_of_use')}</a>
                    </span>
                    <span className="info-link small-margin-top-mobile">
                        <a href={"https://" + languageSubdomain + ".advocateaurorahealth.org/livewell/faq"} target="_blank" rel="noreferrer" 
                            aria-label="FAQ. Link opens in a new tab">{i18n.t('faq')}</a>
                        <span className="vertical-bar vertical-bar-mobile">{(i18n.language != 'es') ? ' | ' : ''}</span>
                    </span>
                    <span className="info-link inline-mobile">
                        <a href={"https://" + languageSubdomain + ".advocateaurorahealth.org/notice-of-privacy-practices/"} target="_blank" rel="noreferrer" 
                            aria-label="Notice of Privacy Practices. Link opens in a new tab">{i18n.t('notice_of_privacy_practices')}</a>
                    </span>
                    <span className="info-link">
                        <a href={"https://" + languageSubdomain + ".advocateaurorahealth.org/notice-of-nondiscrimination/"} target="_blank" rel="noreferrer" 
                            aria-label="Notice of Nondiscrimination. Link opens in a new tab">{i18n.t('notice_of_non_discrimination')}</a>
                    </span>
                </div>                
            </FooterStyled>
            {version && <VersionContainer>Version - {version}</VersionContainer>}
        </footer>
    );
}