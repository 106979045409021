import styled from 'styled-components';

export const HeaderContainerStyled = styled.header`
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-bottom: 1rem;
`
export const LanguageButton = styled.button`
    display: none;
    @media (min-width: 1025px) {
        display: block;
        position: absolute;
        top: 115px;
        right: 0;
        font-size: 12px;
        background: none;
        border: none;
        color: #1175A7;
        cursor: pointer;      
    }
`

export const HeaderSubContainerStyled = styled.div`
    max-width: 1075px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 94%;
    height: 46.43px;
    .header-flex-item-1 {
        width: 101.97px;
        img {
            width: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            @media (min-width: 1025px) {
                top: 1.75rem;
                transform: none;       
            }
        }
        @media (min-width: 1025px) {
            width: 174px;            
        }
    }
    .header-flex-item-2 {
        overflow: hidden;
        flex-grow: 1;
        @media (min-width: 1025px) {
            padding-top: 2.75rem;
            width: 70%;       
        }
    }
    .header-flex-item-3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4rem;
        flex-grow: 1;
        @media (min-width: 1025px) {
            position: static;
            transform: none;
            width: auto;
            padding-top: 2.6rem;
        }
    }
    .text-align-right {
        margin-right: 0;
        text-align: right;
    }
    @media (min-width: 1025px) {
        position: relative;
        height: 5.75rem;
    }
`;


export const NavListStyled = styled.ul`
    z-index: 99999;
    list-style: none;
    position: absolute;
    top: 46.43px;
    left: 0;
    margin: 0;
    width: 100vw;
    background-color: #efefef;
    padding: 0;
    @media (min-width: 1025px) {
        background-color: #ffffff;
        position: static;
        width: auto;
    }
    @media (min-width: 1025px) {
        .border {
            z-index: 99999;
            top: 18px;
            position: relative;
            border-top: 5px solid #1175A7;
            left: 0;
        }
    }
    li.nav-item-main {
        width: 100%;
        border-bottom: 1px solid #ddd;
        text-align: left;
        cursor: pointer;
        a:first-child {
            padding: 1rem;
        }
        a {
            display: block;
            height: 100%;
            font-weight: 600;
            &.sub-nav {
                font-weight: 500;
            }
        }
        @media (max-width: 1025px) {
            position: relative;
        }
        @media (min-width: 1025px) {
            height: 50px;
            width: 12rem;
            border-bottom: none;
            display: inline-block;
            padding: 0;
            text-align: center;
            a:first-child {
                padding: 0;
            }
        }
    }
    &.hide {
        display: none;
        @media (min-width: 1025px) {
            display: block;        
        }
    }
    svg {
        overflow: visible;
        position: relative;
        right: 6px;
        top: 3px;
    }
`;

export const LanguageDropdownButton = styled.div`
    width: 100%;
    padding: 16px 10px;
    float: left;
    background: white;
    text-align: left;
    color: #1175A7;
    cursor: pointer;
    font-size: 12px;
    border-bottom: 2px solid #ddd;
`

export const NavSubListStyled = styled.div`
    ul {
        list-style: none;
        background-color: #fff;
        padding: 0;
        @media (min-width: 1025px) {
            z-index: 9999;
            position: relative;
            top: -7px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
        }
    }
    li {
        text-align: left;
        cursor: pointer;
        a {
            display: block;
            padding: .75rem 2rem !important;
        }
        &:first-child a {
            padding-top: 2rem !important;
        }
        &:last-child a {
            padding-bottom: 2rem !important;
        }
    }
    &.hide {
        display: none;
    }

    @media (min-width: 1025px) {
        position: absolute;
    }
    svg {
        margin-left: 16px;
    }
`;

export const MenuButton = styled.button`
    display: inline-block;
    position: relative;
    top: 7px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    @media (min-width: 1025px) {
        display: none;        
    }
    &.sub-menu-button {
        color: #1175A7;
        position: absolute;        
        width: 3rem;
        top: 0;
        right: 0;
        height: 52px;
        z-index: 0;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &.plus, &.minus {
        z-index: 9;
        svg {
            z-index: 0;
        }
    }
    .container {
        display: inline-block;
        cursor: pointer;
    }
    .bar1, .bar2, .bar3 {
        width: 20px;
        height: 2px;
        background-color: #333;
        margin: 6px 0;
    }
    .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-8px, 6px);
        transform: rotate(-45deg) translate(-3px, 6px);
    }
    .change .bar2 {
        opacity: 0;
    }
    .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-9px, -8px);
        transform: rotate(45deg) translate(-5px, -8px);
    }
`;
