import React from 'react';
import { CarrouselIndicatorStyled } from './CarrouselStyles.js';

export default function CarrouselIndicator(props) {

    function handlePressEnter(e) {
        if (e.keyCode === 13) {
            props.scrollTo(e);
        }
    }

    return(
        <CarrouselIndicatorStyled data-indicator-number={props.indicatorNumber} onClick={props.scrollTo} role="button" aria-label={`Scroll carousel to section ${props.indicatorNumber}`} tabIndex="0" onKeyDown={handlePressEnter}>
            <svg className="svg"viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0.869995C10.0898 0.869995 13 3.78015 13 7.37C13 10.9598 10.0898 13.87 6.5 13.87C2.91015 13.87 0 10.9598 0 7.37C0 3.78015 2.91015 0.869995 6.5 0.869995Z"/>
            </svg>    
        </CarrouselIndicatorStyled>
    );
}