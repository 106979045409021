import React from 'react';
import { SpanningGridStyled } from './PageBuilderStyles.js';
import { PageSection } from '../Page';
import MappedComponent from '../PageBuilder/MappedComponent';

export default function SpanningGrid(props) {
    const { data } = props;
    return (
        <PageSection>
            <SpanningGridStyled>
                {data.children.map((item, i) => {
                    return (                        
                        <MappedComponent {...props} action={item.action} data={item.properties} />                            
                    );
                })}
            </SpanningGridStyled>
        </PageSection>
    );
}