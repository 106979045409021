import React from 'react';
import styled from 'styled-components';

const PrimitiveTextStyled = styled.div`
    margin-bottom: 1rem;
    .xxlarge-text {
        margin-top: ${props => props.padding?.top || 0}px;
        margin-bottom: ${props => props.padding?.bottom || 0}px;
        font-size: 2.5rem;
        font-weight: 700;
        color: ${props => props.data?.text?.style?.color?.light};
        @media (min-width: 769px) {
            font-size: 2.25rem;
        }
    }
    .xlarge-text {
        margin-top: ${props => props.padding?.top || 0}px;
        margin-bottom: ${props => props.padding?.bottom || 0}px;
        font-size: 1.75rem;
        font-weight: 700;
        color: ${props => props.data?.text?.style?.color?.light};
        @media (min-width: 769px) {
            
        }
    }
    .large-text {
        margin-top: ${props => props.padding?.top || 0}px;
        margin-bottom: ${props => props.padding?.bottom || 0}px;
        font-size: 1.5rem;
        font-weight: 900;
        color: ${props => props.data?.text?.style?.color?.light};
        margin-bottom: 0;
    }
    .medium-text {
        margin-top: ${props => props.padding?.top || 0}px;
        margin-bottom: ${props => props.padding?.bottom || 0}px;
        font-weight: 400;
        font-size: .875rem;
        color: ${props => props.data?.text?.style?.color?.light};
        margin-bottom: 0;
        @media (min-width: 769px) {
            font-size: 1rem;
        }
    }
    .small-text {
        margin-top: ${props => props.padding?.top || 0}px;
        margin-bottom: ${props => props.padding?.bottom || 0}px;
        font-weight: 400;
        font-size: .75rem;
        color: ${props => props.data?.text?.style?.color?.light};
        margin-bottom: 0;
        @media (min-width: 769px) {
            font-size: 1rem;
        }
    }
    .default-text {
        margin-top: ${props => props.padding?.top || 0}px;
        margin-bottom: ${props => props.padding?.bottom || 0}px;
        font-weight: 400;
        font-size: .875rem;
        color: ${props => props.data?.text?.style?.color?.light};
        margin-bottom: 0;
        @media (min-width: 769px) {
            font-size: 1rem;
        }
    }
`;

function renderTextBySize(size, text) {
    // TabIndex causes an element to be read as group, assigned a heading role to be read as heading instead
    switch (size) {
        case 'xxlarge':
            return <div role="heading" aria-level="1" className="xxlarge-text">{text}</div>;
        case 'xlarge':
            return <div role="heading" aria-level="2" className="xlarge-text">{text}</div>;
        case 'large':
            return <div role="heading" aria-level="3" className="large-text">{text}</div>;
        case 'medium':
            return <div role="heading" aria-level="4" className="medium-text">{text}</div>;
        case 'small':
            return <div role="heading" aria-level="5" className="small-text">{text}</div>;
        case 'default':
            return <div role="heading" aria-level="6" className="default-text">{text}</div>;
        default:
            return <div role="heading" aria-level="7" className="default-text">{text}</div>;
    }
}

export default function PrimitiveText(props) {
    const size = props.data?.text?.style?.size;
    const text = props.data?.text?.value;

    return (
        <PrimitiveTextStyled {...props}> 
            {renderTextBySize(size, text)}
        </PrimitiveTextStyled>
    );
}