import React from 'react';
import { Link } from 'react-router-dom';
import { LogoWithTextStyled } from './PageStyles';
import Logo from '../../img/Logo.png'

export default function LogoWithText(props) {
    const {size} = props;
    return (
        <LogoWithTextStyled className={size}>
            <Link className="link-main" to="/" aria-label="Livewell home page"><img src={Logo} alt="Livewell"></img></Link>
        </LogoWithTextStyled>
    );
}