import { Link, useParams } from 'react-router-dom';
import TitleAnnouncer from '../components/Page/TitleAnnouncer';
import i18n from '../i18n';

export default function CurrentPageNav(props) {
    const { title } = useParams();

    function CreateLinks() {
        if (props.links) {
            let content = [];
            for (let link of props.links) {
                content.push(
                    <>
                        &gt;
                        <Link to={link[1]}> {link[0]} </Link>
                    </>
                )
            }
            return content;
        } else {
            return (<></>)
        }
    }

    return (
        <span style={{ fontWeight: "500" }}>
            <Link to="/">{i18n.t('home')} </Link>
            <CreateLinks />
            {(title || props.title) &&
                <>
                    <span style={{ color: "#707070" }} aria-hidden="true">&gt;&nbsp;</span>
                    <span style={{ color: "#707070" }}>
                        <TitleAnnouncer title={title ?? props.title} isAssertive={false} ariaLabelText={`${title ?? props.title}, your current page`} />
                    </span>
                </>
            }
            <br />
        </span>
    )
}