import styled from 'styled-components';

export const GraphicTextContentCardStyled = styled.div`
    position: relative;
    flex-shrink: 0;
    margin-right: ${props => props.carouselView ? '2rem' : '0'};;
    color: #535353;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #efefef;
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

    width: ${props => props.carouselView ? 'calc(80% - .5rem)' : '100%'};

    @media (min-width: 481px) {
        width: ${props => props.carouselView ? 'calc(50% - .5rem)' : '100%'};
    }

    @media (min-width: 769px) {
        width: ${props => props.carouselView ? 'calc(33% - 4.25rem)' : '100%'};
    }

    img {
        border-radius: 8px 8px 0 0;
        width: 100%;
        height: auto;
        max-height: 367.37px;
        object-fit: cover;
    }

    h4 {
        font-size: 1rem;
        margin: .5rem 1rem .5rem 1rem;
        padding-top: .5rem;
        font-weight: 700;
        @media (min-width: 769px) {
            margin: 1rem 1rem .5rem 1rem;
            padding-top: 0;
            width: 67%;
            border-top: none;
            font-size: 18px;
            font-weight: 600;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    .title-container {
        position: relative;
        min-height: 5rem;
    }
    
    .description {
        display: block;
        font-size: .825rem;
        margin: .5rem 1rem 1rem 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        @media (min-width: 769px) {
            padding-bottom: 1rem;
        }
    }

    .viewed-border {
        @media (max-width: 768px) {
            position: absolute;
            left: 1rem;
            top: 1.75rem;
            width: 80%;
            border-top: 1px solid #63666A;
        }
    }
    
    .viewed-icon {
        position: absolute;
        top: 5.5px;
        height: 12px;
        width: 28px;
        margin-left: .6rem;
        @media (min-width: 769px) {
            right: 25px;
            top: 0;
            height: 16px;
            margin-left: 0;
        }
    }

    .viewed {
        padding: 0 0 0 2.5rem;
        font-size: 12px;
        color: #63666A;
        font-weight: 600;
        @media (min-width: 769px) {
            position: absolute;
            right: 10px;
            top: 1rem;
            padding: 0;
            font-size: 16px;
            color: #63666A;
            font-weight: 600;
        }
    }

    .not-viewed {
        display: none;
    }
`;

export const HorizontalCardStyled = styled.a`
    width: 100%;
    border-radius: 8px;
    background-color: ${props => props.item.background.color.light};
    height: 5rem;
    display: inline-flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
    min-height: 4rem;
    color: #535353;
    margin-bottom: 1rem;
    cursor: pointer;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: 769px) {
        width: calc(33.3% - 1rem);
        min-height: 7.75rem;
        padding: 0 .5rem 0 .5rem;
        margin-right: 1.5rem;
    }

    @media (min-width: 900px) {
        padding: 0 1.5rem 0 1.5rem;
    }

    .flex-item-1 {
        width: 15%;

        img {
            width: 1.75rem;
        }

        @media (min-width: 481px) {
            img {
                width: 2.5rem;
            }
        }

        @media (min-width: 1025px) {
            width: 25%;
            img {
                width: 3.25rem;
            }
        }
    }

    .flex-item-2 {
        padding: 0 1rem 0 1rem;

        h4 {
            font-size: 1.125rem;
            margin: 0 0 .5rem 0;
            color: ${props => props.item.title_style.color.light};

            @media (min-width: 769px) {
                font-size: 1rem;
            }

            @media (min-width: 1025px) {
                font-size: 1.25rem;
            }
        }

        .subtext {
            display: block;
            font-size: .75rem;
            color: ${props => props.item.message_style.color.light};
            font-weight: ${props => props.item.message_style.weight};

            @media (min-width: 769px) {
                font-size: 1rem;
            }
        }
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

export const IconCardStyled = styled.div`
    flex-shrink: 0;
    margin-right: 1.66rem;
    position: relative;
    min-height: 5rem;
    color: #535353;
    font-weight: ${props => props.item.footer.style.weight};
    margin-left: .75rem;
    width: calc(20% - 1.75rem);
    cursor: pointer;
    color: ${props => props.item.footer.style.color.light};

    @media (min-width: 481px) {
        height: 7rem;
        margin-left: 0;
    }

    @media (min-width: 769px) {
        position: static;
        height: auto;
        margin-right: 2rem;
    }

    .icon-container {
        width: 3rem;
        height: 3rem;
        border-radius: 8px;
        background-color: #F7F7F7;
        position: relative;

        @media (min-width: 481px) {
            width: 5rem;
            height: 5rem;
            min-height: 4rem;
            text-align: center;
        }

        @media (min-width: 769px) {
            width: 100%;
            text-align: left;
        }
    }

    .icon {
        width: 63%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (min-width: 481px) {
            width: 40%;
        }

        @media (min-width: 769px) {
            transform: none;
            max-width: 3rem;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .card-text-mobile {
        text-align: center;
        width: 4.5rem;
        margin: .25rem 0 0 -.66rem;
        @media (min-width: 481px) {
            margin: .25rem 0 0 0;
        }
        @media (min-width: 769px) {
            display: none;
        }
    }

    .card-text-desktop {
       display: none;

        @media (min-width: 769px) {
            width: 4rem;
            position: absolute;
            text-align: ${props => props.item.footer.style.orientation};
            display: block;
            left: 0;
            top: auto;
            bottom: 25%;
            transform: translateY(50%);
            width: 100%;
            font-weight: 600;
            padding: 0 .25rem;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }


    &.max-visible-5 {
        font-size: .69rem;
        @media (min-width: 481px) {
            width: calc(20% - 2rem);
        }

        @media (min-width: 769px) {
            font-size: .75rem;
            width: calc(20% - 1.66rem);
            .icon-container {
                min-height: 9rem;
            }
        }

        @media (min-width: 1025px) {
            font-size: 1.125rem;
            width: calc(20% - 1.66rem);
            .icon-container {
                min-height: 11.75rem;
            }
        }

        @media (min-width: 1025px) {
            font-size: 1.125rem;
            width: calc(20% - 1.66rem);
            .icon-container {
                min-height: 11.75rem;
            }
        }

        @media (min-width: 1200px) {
            font-size: 1.125rem;
            width: calc(20% - 3.6rem);
            .icon-container {
                min-height: 11.75rem;
            }
        }
    }

    &.max-visible-6 {
        font-size: .69rem;
        @media (min-width: 481px) {
            width: calc(16.667% - 1.25rem);
        }

        @media (min-width: 769px) {
            font-size: .75rem;
            margin-right: 1.875rem;
            width: calc(16.667% - 1.55rem);
            .icon-container {
                min-height: 9rem;
            }
        }

        @media (min-width: 1025px) {
            font-size: .875rem;
            width: calc(16.667% - 3.125rem);
        }
    }

    .colorized-image-container {
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (min-width: 769px) {
            top: 40%;
        }
    }
`;


export const MultiBlogFeedCardStyled = styled.div`
    position: relative;
    flex-shrink: 0;
    margin-right: 2rem;
    padding-bottom: 50px;
    color: #535353;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #efefef;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

    &.max-visible-2 {
        img {
            height: 206px;
            @media (min-width: 769px) {
                height: 299px;
            }
        }
    }

    &.max-visible-3 {
        img {
            height: 218px;
            @media (min-width: 769px) {
                height: 245px;
            }
        }
    }

    img {
        border-radius: 8px 8px 0 0;
        width: 100%;
        object-fit: cover;
        &.ar-16-9 {
            aspect-ratio: 16 / 9;
        }
        &.ar-2-3 {
            aspect-ratio: 3 / 2;
        }
    }

    a {
        display: block;
        color: #535353;
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem 1rem .5rem 1rem;
        &::before {
            content: " ";
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
        @media (min-width: 769px) {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .description {
        position: absolute;
        display: block;
        height: 34px;
        margin: 0 1rem 1rem 1rem;
        white-space: nowrap;
        font-size: .825rem;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    &.max-visible-2 {
        width: calc(80% - .5rem);
        @media (min-width: 481px) {
            width: calc(50% - 5.75rem);
        }
    }

    &.max-visible-3 {
        width: calc(80% - .5rem);
        @media (min-width: 481px) {
            width: calc(50% - .5rem);
        }
        @media (min-width: 769px) {
            width: calc(33% - 4.25rem);
        }
    }
`;

let cardHeightMobile = "12.33rem";
let cardHeightDesktop = "16.75rem";

export const VerticalCardStyled = styled.div`
    height: ${cardHeightMobile};
    background-color: #f6f6f6;
    position: relative;
    border: 1px solid #efefef;
    border-radius: 8px;
    width: 100%;

    @media (min-width: 769px) {
        height: ${cardHeightDesktop};
    }

    &.pointer:hover {
        cursor: pointer;
    }

    .card-container {
        position: absolute;
        text-align: center;
        width: 100%;
        top: 1rem;

        @media (min-width: 481px) {
            top: 50%;
            transform: translateY(-50%);
        }

        a {
            text-decoration: none;
            color: #117BB0;
            &::before {
                content: " ";
                position: absolute;
                height: ${cardHeightMobile};
                width: 100%;
                top: calc((${cardHeightMobile} - 100%) / -2); /* height of outermost div minus 100% of inner .card-container height, divided in half (negative so that it goes up Y axis) */
                left: 0;
                @media (min-width: 769px) {
                    height: ${cardHeightDesktop};
                    top: calc((${cardHeightDesktop} - 100%) / -2);
                }
            }
        }
    }

    img {
        height: 1.875rem;
        width: auto;
        @media (min-width: 769px) {
            height: 4.2rem;
        }
    }

    .text, .text > a {
        margin-top: .5rem;
        margin-bottom: .25rem;
        margin-left: auto;
        margin-right: auto;
        color: #117BB0;
        font-weight: 900;
        font-size: .875rem;
        line-height: 1rem;
        width: 90%;

        @media (min-width: 769px) {
            font-size: 1.313rem;
            line-height: 1.75rem;
        }
    }

    .description {
        width: 70%;
        margin: .875rem auto 0 auto;
        color: #707070;
        font-size: .875rem;
        line-height: 1.125rem;

        @media (min-width: 769px) {
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
    }
`;

export const ManualImageScrollerCardStyled = styled.div`
    margin-right: 1.25rem;
    flex-shrink: 0;
    width: 100%;

    .manual-image-scroller-grid {
        display: grid;
        position: relative;
        &.left-image {
            grid-template-columns: 35% 1fr;
            grid-template-rows: 1fr 1fr;
            @media (min-width: 769px) {
                grid-template-rows: auto auto;
            }
            .image {
                grid-column: 1;
                grid-row: 1 / 3;
                padding-right: .5rem;
                @media (min-width: 769px) {
                    padding-right: 3rem;
                }
            }
            .title {
                grid-column: 2;
                grid-row: 1 / 2;
                padding-left: .25rem;
                display: flex;
                align-items: flex-end;
                margin-bottom: 1rem;
                .title-text {
                    padding-bottom: 1rem;
                    font-size: 1.125rem;
                    font-weight: 700;
                    color: #535353;

                    @media (min-width: 769px) {
                        font-size: 1.625rem;
                    }
                }
            }
            .description {
                grid-column: 2;
                grid-row: 2 / 3;
                .description-text {
                    padding-right: 2rem;
                }
            }
        }

        &.right-image {
            grid-template-columns: 1fr 35%;
            grid-template-rows: 1fr 1fr;
            @media (min-width: 769px) {
                grid-template-rows: auto auto;
            }
            .image {
                grid-column: 2;
                grid-row: 1 / 3;
                padding-left: .5rem;
                @media (min-width: 769px) {
                    padding-left: 3rem;
                }
            }
            .title {
                grid-column: 1;
                grid-row: 1 / 2;
                padding-left: .25rem;
                display: flex;
                align-items: flex-end;
                margin-bottom: 1rem;
                .title-text {
                    padding-bottom: 1rem;
                    font-size: 1.125rem;
                    font-weight: 700;
                    color: #535353;

                    @media (min-width: 769px) {
                        font-size: 1.625rem;
                    }
                }
            }
            .description {
                grid-column: 1;
                grid-row: 2 / 3;
                .description-text {
                    padding-right: 2rem;
                }
            }
        }

        img {
            border-radius: 24px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .description {
            .description-text {
                color: #2e2e2e;
                font-size: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
                -webkit-box-orient: vertical;
                @media (min-width: 769px) {
                    display: block;
                    -webkit-line-clamp: none; /* number of lines to show */
                    line-clamp: none;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    .legacy-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
