import styled from 'styled-components';

export const ContainerStyled = styled.div`
    max-width: 1068px;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0 1rem 2rem 1rem;
    flex-shrink: 0;

    @media (min-width: 1201px) {
        padding: 0 0 2rem 0;
    }

    &.no-top-margin {
            @media (min-width: 769px) {
            margin-top: 0;
        }
    }
`;

export const PageHeaderStyled = styled.div`
    margin-bottom: 40px;
    h1.title {        
        font-size: 1.875rem;
        color: #535353;
        font-weight: 900;   

        @media (min-width: 481px) {
            font-size: 2.5rem;
            margin: 2rem 0 1rem 0;
            letter-spacing: 0.0825rem;
        }

    }
    h3.description {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        font-weight: 400;
        color: #707070;
        @media (min-width: 481px) {
            font-size: 1.125rem;
        }
    }
`;

export const SectionStyled = styled.div`
    margin-top: 3rem;

    .flex-container {
        display: inline-flex;
        width: 100%;
        margin: 0 0 2rem 0;
    }

    .flex-item.headings {
        width: 70%;
    }

    .flex-item.section-link {
        width: 30%;
        text-align: right;
        white-space: nowrap;
    }

    .section-link svg {
        position: relative;
        top: 7px;
        margin-left: .5rem;
    }

    h2.section-heading {
        margin: 0;
        padding: 0;
        font-size: 1.75rem;
        font-family: Montserrat;
        font-weight: bold;
        color: #535353;
    }

    h3.section-subheading {
        margin: 0;
        padding: 0;
        font-size: 1.125rem;
        font-weight: 400;
        color: #707070;
    }

    .link {
        position: relative;
        top: 5px;
        color: #007db8;
        font-weight: 600;
        font-size: 1rem;
    }

    .section-showAll {
        position: relative;
        top: 8px;
        color: #117BB0;
        cursor: pointer;
        font-weight: bold;
    }
`;

export const SmartAppBannerStyled = styled.div`
    width: 100%;
    background-color: #F2F1F6;
    height: 6.6875rem;
    display: grid;
    grid-template-columns: 10% 17% 59% auto;
    grid-template-rows: 1fr;    

    .grid-item {
        position: relative;
    }
`;

export const GridContentStyled = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &.text-container {
        padding-left: 0.73rem;
    }

    .close-btn {
        display: block;
        text-align: center;
        &:hover {
            cursor: pointer;
        }
    }

    img {
        max-width: 63.24px;
        max-height: 62px;
    }

    .title {
        font-style: normal;
        font-weight: normal;
        font-size: 1.0625rem;
        line-height: 1.25rem;
        color: #000;
        font-family: Helvetica, sans-serif;
    }

    .subtitle {
        font-size: 0.875rem;
        line-height: 1.0625rem;
        color: #535353;
        font-family: 'SF Pro Display', sans-serif;
    }

    .text {
        font-size: 0.875rem;
        line-height: 1.0625rem;
        color: #535353;
        font-family: 'SF Pro Display', sans-serif;
    }

    .banner-link {
        font-size: 1.0625rem;
        line-height: 1.25rem;
        color: #21A2FF;
        font-family: 'SF Pro Display', sans-serif;
    }
`;

export const StateSelectorModalStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, .72);
    width: 100vw;
    height: 100vh;
    z-index: 99999;

    .state-selector-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 805px;
        margin: 0 auto;
        height: 470px;
        background-color: #fff;
        text-align: center;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.160784);
        border-radius: 8px;

        @media (min-width: 481px) {
            width: 75%;
            height: 596px;
        }

        @media (min-width: 769px) {
            width: 57%;
        }
    }

    .close-btn {
        position: absolute;
        right: 1.625rem;
        top: 1.625rem;
        &:hover {
            cursor: pointer;
        }

        svg {
            width: 1.250rem;
            height: 1.250rem;
        }
    }

    .content {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .title-text {
        font-size: 1.5rem;
        line-height: 1.625rem;
        font-weight: 900;
        color: #63666A;
    }

    .cards {
        display: block;
        width: 100%;

        @media (min-width: 769px) {
            display: flex;
            justify-content: center;
        }

        .state-card {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            background: #F6F6F6;
            border: 1px solid #EFEFEF;
            border-radius: 8px;
            margin-top: 2rem;
            min-height: 110px;
            position: relative;

            @media (min-width: 769px) {
                width: 40%;
                min-height: 252px;
                margin-left: 0;
                margin-right: 0;
                &:not(:last-child) {
                    margin-right: 2rem;
                }
            }               
        }

        .card-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 110px;
            position: relative;
            cursor: pointer;

            @media (min-width: 769px) {
                height: 100%;
            }

            a {
                text-decoration: none;
                color: #117BB0;
                
            }
        }

        img {
            height: 2.5rem;
            width: auto;

            @media (min-width: 769px) {
                height: auto;
                max-height: 110px;
                margin-bottom: 1.75rem;
            }
        }

        .card-text {
            color: #117BB0;
            font-size: 0.875rem;
            line-height: 1.75rem;

            @media (min-width: 769px) {
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
                margin-top: 1.375rem;
                font-size: 1.3125rem;
            }
        }
    }
`;

export const ModalStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, .72);
    width: 100vw;
    height: 100vh;
    z-index: 99999;

    .content-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 1000px;
        margin: 0 auto;
        height: 596px;
        background-color: #fff;
        text-align: center;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.160784);
        border-radius: 8px;
        padding: 0 1rem;
        overflow-x: hidden;
        overflow-y: auto;

        @media (min-width: 481px) {
            width: 75%;
        }

        @media (min-width: 769px) {
            width: 66%;
            padding: 0 2rem;
        }

        @media (max-height: 500px) {
            top: 0;
            height: 95vh;
            transform: translate(-50%, 0);
        }
    }

    .content-wrapper {
        position: relative;
        top: 3rem;
        transform: translateY(0);

        @media (max-height: 500px) {
            top: 2rem;
        }
    }

    .close-btn {
        position: absolute;
        right: 1.625rem;
        top: 1.625rem;
        &:hover {
            cursor: pointer;
        }

        svg {
            width: 1.250rem;
            height: 1.250rem;
        }
    }
`;

export const LogoWithTextStyled = styled.div`
    height: 100%;
    position: relative;
    margin-top: 1.75rem;

    a {
        &.link-main {
            display: block;
            position: absolute;
            transform: translateY(-50%);
            height: 3.64rem;
            width: 14.5rem;
            top: 40%;
        }
        &.link-alt {
            color: #1175A7;
            font-weight: 700;
        }
    }

    img {
        width: 100%;
    }

    .text {
        position: absolute;
        color: #6F6F6F;
        top: 66%;
        font-size: .875rem;
    }
`;