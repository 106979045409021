import { APIHEADER } from "./constants";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const languageCode = cookies.get('languageCode');

export async function getAuth() {
    const res = await fetch(process.env.REACT_APP_API_URL + '/auth', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'AppVersion': '9.2.0',
            'client-name':'livewell',
        },
        body: JSON.stringify({
            'api_key': process.env.REACT_APP_API_KEY,
            'device_type': 'web',
            'is_test_device': false
        })
    });
    return res.json();
}

export async function postAnalytics(token, event) {
    // const res = await fetch(process.env.REACT_APP_API_URL + '/analytics', {
    //     method: 'POST',
    //     headers: {
    //         ...APIHEADER,
    //         'Authorization': token
    //     },
    //     body: JSON.stringify(event)
    // });
    // const data = await res.json();
    // handleAPIError(data);
    // return data;
    return -1;
}

export async function getHomeData(token) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/home', {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getFindCareData(token) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/page/1', {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getMeditationsData(token) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/meditation_categories?includeExercises=true&page=1&itemsPerPage=20', {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getFeaturedMeditations(token) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/meditation_categories/featured', {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getMeditationsDataByCategory(token, slug) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/meditation_categories/slug/' + slug, {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getBgSoundData(token) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/meditation_background_tracks', {
        method: 'GET',
        headers: {
          ...APIHEADER,
          'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getEmergencyAlerts(token) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/emergency_alerts', {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getStateSelectorData(token, link) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/${link}`, {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getMultiBlogsData(token, feedType) {
    const res = await fetch(process.env.REACT_APP_API_URL + `/multi_blogs/${feedType}`, {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export async function getWellnessData(token) {
    const res = await fetch(process.env.REACT_APP_API_URL + '/page/3', {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}

export function handleAPIError(data) {
    if (data.success === false) {
        if (data.http_code === 401) {
            // auth token cookie should be removed if 401 error gets returned
            document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
        return { error: data.message, data: null };
    }
}

export async function getPageBuilderPage(token, pageId) {
    const res = await fetch(process.env.REACT_APP_API_URL + `/page/${pageId}`, {
        method: 'GET',
        headers: {
            ...APIHEADER,
            'Authorization': token,
            'Accept-Language': languageCode
        }
    });
    const data = await res.json();
    handleAPIError(data);
    return data;
}