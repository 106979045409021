import { Switch, Route } from 'react-router-dom';

// components
import Home from './components/Home';
import Wellness from './components/Wellness';
import FindCare from './components/FindCare';
import Meditations from './components/Meditations';
import ShowAllMeditations from './components/Meditations/ShowAllMeditations';
import ErrorPage from './components/Error/ErrorPage';
import ErrorBoundary from './components/Error/ErrorBoundary';
import i18n from './i18n';

function Routes() {
  let wellness = i18n.t('wellness');
  let meditations = i18n.t('meditations');
  return (    
        <Switch>
            <Route path="/" exact render={props => (
                <ErrorBoundary {...props} onRoute={true} >
                    <Home />
                </ErrorBoundary>
            )} />
            <Route path="/wellness" exact render={props => (
                <ErrorBoundary {...props} onRoute={true}>
                    <Wellness title={wellness} />
                </ErrorBoundary>
            )}/>
            <Route path="/wellness/guided-meditation" exact render={props => (
                <ErrorBoundary {...props} onRoute={true}>
                  <Meditations links={[[wellness, "/wellness"]]} title={meditations} />
                </ErrorBoundary>
            )} />
            <Route path="/wellness/guided-meditation/:slug" render={props => (
                <ErrorBoundary {...props} onRoute={true}>
                  <ShowAllMeditations links={[[wellness, "/wellness"], [meditations, "/wellness/guided-meditation"]]} />
                </ErrorBoundary>
            )} />
            <Route path="/find-health-care" render={props => (
                <ErrorBoundary {...props} onRoute={true}>
                    <FindCare title={i18n.t('find_care')} />
                </ErrorBoundary>
            )} />
            <Route path="*">
                <ErrorPage httpCode="404" />
            </Route>
        </Switch>
  );
}

export default Routes;
