import React, { useState, useEffect, useContext } from 'react';
import { Page, InternalPageOverlay } from '../Page';
import { MappedComponent } from "../PageBuilder";
import EmergencyAlert from './EmergencyAlert';
import { AppSystemMessage } from './';
import { postAnalytics, getHomeData } from '../../common/api';
import { SEO } from "../Page";
import ErrorPage from "../Error/ErrorPage";
import LoadingIndicator from '../Loading/LoadingIndicator';
import GlobalContext from '../../GlobalContext';
import UseSystemMessage from '../../hooks/UseSystemMessage';
import UseInternalPageOverlay from '../../hooks/UseInternalPageOverlay';
import TitleAnnouncer from '../Page/TitleAnnouncer';

export default function Home() {
    const [homeData, setHomeData] = useState(false);
    const [apiStatus, setApiStatus] = useState({success: true, httpCode: ""});
    const [isLoading, setIsLoading] = useState(true);    
    const { emergencyAlert, token } = useContext(GlobalContext);
    const [alertData] = emergencyAlert;
    const { showSystemMessage, dismissMessage, handleSystemMessage } = UseSystemMessage();
    const internalOverlayPage = UseInternalPageOverlay();

    useEffect(() => {        
        if (token) {
            setData();
            if (alertData.data === null) {
                setEmergencyAlert();
            }
        }

        async function setData() {
            try {
                setIsLoading(true);
                const res = await getHomeData(token);
                setIsLoading(false);
                handleSystemMessage(res.app_start_system_message);
                const data = res.homescreen_page_builder; 
                data.app_start_system_message = res.app_start_system_message;    
                setHomeData(data);
                postAnalytics(token, {
                    "event_name": "home_view",
                    "event_data": {
                        "page_id": data.analytic_additional_params.page_id
                    }
                });
            } catch(error) {
                setIsLoading(false);
                setApiStatus({
                    success: false,
                    httpCode: error.http_code ? error.http_code : "500"
                });
            }
        }

        async function setEmergencyAlert() {
            const res = await emergencyAlert[0].setAlert(token);
        }

    }, [token]);

    return (
        <>
            <SEO title="Home | LiveWell | Advocate Aurora Health"
                 description="LiveWell helps you manage your health and wellness. Use it to view test results, schedule appointments, message your doctor and more." />
            <TitleAnnouncer title='Livewell home' isAssertive={false} screenReaderOnly={true} />

            {apiStatus.success && !isLoading && <Page noTopMargin="true">                
                {alertData.showAlert && <EmergencyAlert heading={alertData.data?.items?.[0]?.value}
                                            text={alertData.data?.items?.[1]?.value}
                                            linkText={alertData.data?.items?.[2]?.value}
                                            link={alertData.data?.items?.[2]?.action?.web_redirect}
                                            closeButton={alertData.data?.dismiss_icon?.dark} />}
                {/* homeData && showSystemMessage && <AppSystemMessage data={homeData.app_start_system_message} dismissMessage={dismissMessage} /> */}      
                {homeData && homeData.content.map((item, i) => {
                    return <MappedComponent
                        key={i} 
                        analytic_name_prefix={homeData.analytic_name_prefix} 
                        page_id={homeData.analytic_additional_params.page_id}
                        data={item} 
                        token={token} 
                        title="Home"
                    />
                })}
            </Page>}
            
            {!apiStatus.success && <ErrorPage httpCode={apiStatus.httpCode} />}

            {isLoading && 
                <Page>
                    <LoadingIndicator />
                </Page>            
            }

            {internalOverlayPage.show && <InternalPageOverlay internalOverlayPage={internalOverlayPage} />}
            
        </>                  
    );
}