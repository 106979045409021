import React from 'react';
import Carrousel from '../../components/Carrousel';
import { IconCard } from '../../components/Cards';
import { PageSection } from '../../components/Page';

export default function IconCards(props) {
    const { data } = props;
    const items = data.data;
    let visibleCount;
    if (data.top_section?.title?.value.toLowerCase().indexOf("quick links") !== -1) {
        visibleCount = "5";
    } else {
        visibleCount = "6";
    } 
    return (
        <PageSection heading={data.top_section?.title?.value} 
            subheading={data.top_section?.subtitle?.value}
            linkText={data.bottom_section?.button?.text?.value} 
            link={data.bottom_section?.button?.text?.value}>    
            <Carrousel visibleCount={visibleCount} max={data.data.length} showControls="true">
                {items.map((item, i) => {
                    return <IconCard {...props} key={i} visibleCount={visibleCount} item={item} />

                })}
            </Carrousel>
        </PageSection>
    );
}