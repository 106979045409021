import React, { useEffect, useRef } from 'react';
import { GraphicTextContentCardStyled } from './CardStyles.js';
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function GraphicTextContentCard(props) {
    const { title, description, image, link, carouselView } = props;
    const card = useRef();
    const history = useHistory();
    const [cookies] = useCookies(['viewedMeals']);
    
    const routeChange = () => { 
        let path = link; 
        history.push(path);
    }
    
    const isVisited = cookies.viewedMeals?.includes(link.replace("/wellness/meal-plans/", ""));

    useEffect(() => {
        card.current.onkeydown = handlePressEnter;
    }, []);

    function handlePressEnter(e) {        
        if (e.key === "Enter") {
            routeChange();
        }
    }

    return (
        <GraphicTextContentCardStyled ref={card} onClick={routeChange} carouselView={carouselView} role="button" aria-label={`Link. ${title || ''}. ${description || ''}`} tabIndex="0">
            <img src={image} alt={image.alt} title="Thumbnail image" />
            <div className="title-container">
                <svg className={`${(isVisited) ? 'viewed-icon' : 'not-viewed' }`} width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.1751 18.6769C12.3001 18.6565 10.6967 18.4065 9.14268 17.8752C7.59794 17.3511 6.14545 16.5868 4.83871 15.6105C4.08092 15.0422 3.37494 14.4079 2.72905 13.715C1.94973 12.881 1.26384 11.9644 0.683596 10.9815C0.526778 10.7173 0.38246 10.4451 0.234164 10.1758C-0.0931084 9.57921 -0.076063 8.98262 0.277346 8.41046C0.70746 7.70932 1.12962 7.00137 1.59894 6.32694C1.91939 5.86671 2.2995 5.44569 2.67848 5.03035C3.29453 4.34319 3.97765 3.71926 4.71769 3.16785C5.30602 2.72988 5.91834 2.32508 6.55178 1.95535C8.0074 1.12738 9.59236 0.551104 11.2398 0.250801C11.9236 0.120558 12.6168 0.0455053 13.3126 0.0263697C13.5484 0.0201197 13.7847 0.000233355 14.0205 0.000233355C15.0035 -0.00601664 15.9739 0.11387 16.9387 0.28887C18.1319 0.507051 19.2682 0.913869 20.3717 1.41103C21.3393 1.84681 22.2543 2.39122 23.0989 3.03376C23.5864 3.40535 24.0506 3.81103 24.5086 4.22012C25.4156 5.03914 26.2093 5.97542 26.8688 7.00421C27.241 7.56881 27.5758 8.15719 27.8711 8.76557C28.0819 9.20762 28.0273 9.68205 27.7881 10.132C27.3822 10.9028 26.919 11.642 26.4023 12.3434C25.8656 13.0711 25.2651 13.7495 24.608 14.3707C23.7794 15.1551 22.8722 15.852 21.9006 16.4502C20.9366 17.0437 19.907 17.5234 18.8325 17.8798C17.8325 18.2088 16.8126 18.4639 15.7643 18.5701C15.1575 18.6326 14.5472 18.6531 14.1751 18.6769ZM20.9847 9.32296C20.9592 5.53489 17.9842 2.49512 14.2631 2.36501C10.1961 2.22296 6.99723 5.43319 7.01655 9.37409C7.0353 13.1701 10.0398 16.2798 13.92 16.323C17.866 16.3673 20.9751 13.2281 20.9847 9.32296Z" fill="#63666A"/>
                    <path d="M12.77 4.84722C13.3462 4.67767 13.9508 4.62702 14.5472 4.69835C15.7455 4.83869 16.7597 5.35119 17.545 6.26597C19.1171 8.09778 19.07 10.7546 17.4086 12.533C16.65 13.3508 15.6219 13.8675 14.513 13.9882C13.4041 14.1088 12.2889 13.8253 11.3722 13.1898C9.4336 11.866 9.04269 9.55346 9.52507 8.11597C10.5847 8.74437 11.6273 8.75403 12.5217 7.85972C13.416 6.9654 13.3978 5.92847 12.77 4.84722Z" fill="#63666A"/>
                </svg>
                <span className={`${(isVisited) ? 'viewed' : 'not-viewed' }`}>viewed</span>
                <span className={`${(isVisited) ? 'viewed-border' : '' }`}></span>
                <h4>{title}</h4>    
                {description && <span className="description">{description}</span>}
            </div>
        </GraphicTextContentCardStyled>
    );
}