import { useState, useEffect, useContext } from "react";
import { getPageBuilderPage } from '../common/api';
import GlobalContext from '../GlobalContext';
import { useLocation } from 'react-router-dom';

export default function UseAuth() {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const { token } = useContext(GlobalContext);
    let location = useLocation();

    useEffect(() => {
        // check if url has a hash location in format of #page-{pageId} - if it does, launch app with modal showing internal page inside
        if (/^#page-/i.test(window.location.hash)) {        
            setShow(true);
        }
    }, []);

    useEffect(() => {
        const hasValidHash = isValidPageHash();
        setShow(hasValidHash);
    }, [location]);

    function isValidPageHash() {
        if (/^#page-/i.test(window.location.hash)) {        
            return true;
        } else if (window.location.hash === "#classes-and-events") {
            return true;
        } else {
            return false;
        }
    }
    
    async function getData() {
        let pageId;
        // below check is temporary until backend support for named pages is added
        if (window.location.hash === "#classes-and-events") {
            pageId = '19';
        } else {
            pageId = window.location.hash.replace('#page-', '');
        }
        const data = await getPageBuilderPage(token, pageId);
        setData(data);
    }    

    return {show, setShow, data, setData, location, getData};
}