import React, { useEffect, useState, useContext } from 'react';
import { useCookies } from "react-cookie";
import { HeaderContainerStyled, LanguageButton, HeaderSubContainerStyled } from './HeaderStyles.js';
import Nav from './Nav';
import MyChartModal from './MyChartModal';
import { Link } from 'react-router-dom';
import SimpleLogo from '../../img/SimpleLogo.png';
import SignInButton from './SignInButton';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { toggleLanguage } from '../../hooks/languageUtils';

export default function Header() {
    const [cookies, setCookie] = useCookies(['selectedLanguage', 'languageCode']);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [toggleLanguageText, setToggleLanguageText] = useState(cookies.selectedLanguage || 'Español'); // Initial language set to English
    const [languageCode, setLanguageCode] = useState(cookies.languageCode || 'en');

    const { token } = useContext(GlobalContext);

    useEffect(() => {
        if (window.location.hash === "#open-frame") {
            setIsModalOpen(true);
        }
    }, []);

    function handleMyChartModal() {
        setIsModalOpen(true);
        postAnalytics(token, {
            "event_name": "login_view"
        });
    }

    const handleToggleLanguage = () => {
        toggleLanguage(toggleLanguageText, setToggleLanguageText, setLanguageCode, setCookie);
    };

    return (
        <HeaderContainerStyled>
            {isModalOpen && <MyChartModal setIsModalOpen={setIsModalOpen} />}
            <HeaderSubContainerStyled>
                <link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'></link>
                <div className="header-flex-item-1">
                    <Link to="/"><img src={SimpleLogo} alt="Livewell"></img></Link>
                </div>
                <div className="header-flex-item-2 text-align-right">
                    <Nav modalState={[isModalOpen, setIsModalOpen]} />
                </div>
                <div className="header-flex-item-3 text-align-right">
                    <SignInButton handleMyChartModal={handleMyChartModal}/>
                </div>     
                <LanguageButton onClick={handleToggleLanguage}>{toggleLanguageText}</LanguageButton>        
            </HeaderSubContainerStyled>
        </HeaderContainerStyled>
    );
}