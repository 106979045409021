import React from 'react';
import styled from 'styled-components';
import i18n from '../../i18n';

const SignInButtonStyled = styled.button`
    border: none;
    color: #1175A7;
    background-color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: .875rem;
    &:hover {
        cursor: pointer;
    }
    .add-text {
        display: none;
    }
    @media (min-width: 1025px) {
        width: 9.5rem;
        color: #fff;
        background-color: #1175A7;
        padding: .5rem 0;
        border-radius: 8px;
        .add-text {
            display: inline;
        }
    }
    @media (min-width: 1025px) {
        width: 10rem;
    }
`;

export default function SignInButton(props) {
    return (<>
        <SignInButtonStyled onClick={e => props.handleMyChartModal()} aria-label="Sign in to Livewell. Opens modal.">
            {i18n.t('sign_in')} <span className="add-text">{i18n.t('to')} LiveWell</span> 
        </SignInButtonStyled>
    </>);
}