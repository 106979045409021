import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from './LoadingIndicator';

const SectionPlaceholderStyled = styled.div`

    &.meditations-home {
        height: 295px;

        @media (min-width: 1025px) {
            height: 500px;
        }
    }
    &.meditations-wellness {
        height: 295px;

        @media (min-width: 769px) {
            height: 225px;
        }
    }

    &.recipes, &.articles {
        height: 325px;

        @media (min-width: 769px) {
            height: 400px;
        }
    }
`;

export default function SectionPlaceholder(props) {
    const { section } = props;
    return (
        <SectionPlaceholderStyled className={section}>
            <LoadingIndicator />
        </SectionPlaceholderStyled>
    );
}