import React from 'react';
import { ModalStyled } from './PageStyles';

export default function Modal(props) {
    return (
        <ModalStyled>
            <div className="content-container">

            <span className="close-btn" role="button" onClick={e => props.onClick()} aria-label="Close modal">
                <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.02399 1.5L22.024 21.5" stroke="#626262" strokeWidth="3" strokeLinecap="round"/>
                    <path d="M22.024 1.5L2.02399 21.5" stroke="#626262" strokeWidth="3" strokeLinecap="round"/>
                </svg>
            </span>
                <div className="content-wrapper">
                    {props.children}
                </div>
                
            </div>
        </ModalStyled>    
    );
}