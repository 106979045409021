import React from 'react';
import { PrimitiveImageStyled } from './PageBuilderStyles';
import {getClickableLink} from "../../lib/util";

export default function PrimitiveImage(props) {
    const data = props.data;

    let aspectRatio = formatAspectRatio(data.ratio);
    
    function formatAspectRatio(str) {
        let arr = str.split('');
        let index = arr.indexOf(":");
        let first = arr.slice(0, index).join('');
        let last = arr.slice(index + 1, arr.length).join('');
        let result = first + " / " + last;
        return result;
    }

    return (
        <PrimitiveImageStyled style={{aspectRatio}}>
            <a href={getClickableLink(data.action?.web_redirect)} target="_blank" rel="noreferrer" aria-label={"link opens in a new tab"}>
                <img src={data.image?.light} alt="Hero" title="Hero" />
            </a>
        </PrimitiveImageStyled>
    );
}