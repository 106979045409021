import React, { useEffect, useState, useContext } from 'react';
import Carrousel from '../Carrousel';
import MeditationsCard from '../Meditations/MeditationsCard';
import MeditationGrid from '../Meditations/MeditationGrid';
import { PageSection } from '../Page';
import { getFeaturedMeditations, getBgSoundData } from '../../common/api';
import GlobalContext from '../../GlobalContext';
import { SectionPlaceholder}  from '../Loading';
import i18n from '../../i18n';

export default function MeditationsFeatured(props) {
    const { data } = props;
    const { title } = props;
    const heading = data.top_section?.title?.value;
    const [featuredMeditations, setFeaturedMeditations] = useState();
    const [bgSoundData, setBgSoundData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { token } = useContext(GlobalContext);

    useEffect(() => {
        if (token) {
            setData();
            setBgSound();
        }

        async function setData() {
            setIsLoading(true);
            const res = await getFeaturedMeditations(token);
            setIsLoading(false);
            setFeaturedMeditations(res);
        }

        async function setBgSound() {
            const res = await getBgSoundData(token);
            setBgSoundData(res);
        }
    }, [token]);
    
    if (title === "Home" && featuredMeditations) {
        return (
            <PageSection heading={heading} subheading={featuredMeditations.name} link="/wellness/guided-meditation" linkText={data.top_section?.button?.value} >
                <MeditationGrid meditation={featuredMeditations.exercises} sounds={bgSoundData} />
            </PageSection>
        );
    } else if (featuredMeditations) {
        return (
            <PageSection heading={heading} subheading={featuredMeditations.name} link="/wellness/guided-meditation" linkText={data.top_section?.button?.value} >
                    <Carrousel visibleCount="4" max={featuredMeditations.exercises.length} showControls="true">
                    {featuredMeditations.exercises.map((item) => {
                        return <MeditationsCard key={item.id} visibleCount="4" meditation={item} sounds={bgSoundData} />
                    })}
                </Carrousel>
            </PageSection>
        );
    } else if (isLoading) {
        return (
            <PageSection heading={heading} link="/wellness/guided-meditation" linkText={i18n.t('see_more')} >
                <SectionPlaceholder section={`meditations-${title.toLowerCase()}`} />
            </PageSection>
        );
    } else {
        return <></>;
    }
}