import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Cookies from 'universal-cookie';

import enTranslations from './locales/en';
import esTranslations from './locales/es';

const cookies = new Cookies();
const languageCode = cookies.get('languageCode') || 'en'; // Default to 'en' if no cookie

const resources = {
    en: {
        translation: enTranslations
    },
    es: {
        translation: esTranslations
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: languageCode,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
