import styled from 'styled-components';

export const EmergencyAlertStyled = styled.div`
    width: 100%;
    background-color: #f4f4f4;
    padding-left: 16%;
    padding-right: 2.1rem;
    padding-top: 1rem;
    padding-bottom: 2.3rem;
    position: relative;
    margin-bottom: 1rem;

    h4 {
        margin: 0;
        color: #1175A7;
        font-weight: 600;
        font-size: .94rem;
    }

    p {
        font-size: .875rem;
        color: #707070;
    }

    a {
        font-size: .875rem;
    }

    .close-button {
        display: inline-block;
        width: 22px;
        height: 21px;
        position: absolute;
        left: 3.5%;
        top: 1rem;
        cursor: pointer;
        
        img {
            width: 100%;
        }
    }
    
    @media (min-width: 769px) {
        padding-left: 8%;
        h4 {            
            font-size: 1.375rem;
        }

        p {
            font-size: 1.125rem;
        }

        a {
            font-size: 1.125rem;
        }

        .close-button {
            position: absolute;
            left: 3.5%;
            top: 1rem;
        }
    }    

    &.hide {
        display: none;
    }
`;

export const HeroStyled = styled.div`
    margin-top: 1rem;    
    width: 100%;
    flex-shrink: 0;
    margin-right: 1.25rem;
    padding-bottom: 2rem;

    &:last-child {
        margin-right: 0;
    }

    @media (min-width: 481px) {
        margin-top: 0;
    }

    @media (min-width: 769px) {
        width: 100%;
        padding-bottom: 0;
    }

    @media (min-width: 1025px) {
        width: calc(100% - 10rem);
    }

    .flex-container {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .flex-item-1 {
        @media (min-width: 769px) {
            width: 50%;
        }
    }

    .flex-item-2 {
        background-color: #fff;
        width: auto;
        position: relative;        
        @media (min-width: 481px) {
            background-color: #F8F7F6;
            width:auto;
        }
        @media (min-width: 769px) {            
            width:50%;
        }
    }

    .text-container {
        width: 90%;
        margin: 0 auto;
        padding-top: 0;
        padding-left: .25rem;
        color: #535353;

        h3 {
            font-size:1.125rem;
            font-weight: 600;
        }

        p {
            font-size: .75rem;
            line-height: .94rem;
        }

        @media (min-width: 481px) {
            h3 {
                font-size: 1.25rem;
            }
        }

        @media (min-width: 769px) {
            width: 75%;
            padding-left: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);       
            h3 {
                font-size: 1.5rem;
                margin-top: 0;
            }
            p {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }

        @media (min-width: 1025px) {           
            h3 {
                font-size: 1.875rem;
                font-weight: 600;
            }
            p {
                font-size: 1.125rem;
                line-height: 1.875rem;
            }
        }

    }

    img {
        width: 100%;
        height: 125px;
        object-fit: cover;
        padding: 0;
        @media (min-width: 481px) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const AppSystemMessageStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, .72);
    width: 100vw;
    height: 100vh;
    z-index: 999999;

    .close-btn {
        position: absolute;
        right: 1.625rem;
        top: 1.625rem;
        &:hover {
            cursor: pointer;
        }

        svg {
            width: 1.250rem;
            height: 1.250rem;
        }
    }

    .content-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 663px;
        background-color: #fff;
        border-radius: 8px;

        &-inner {
            width: 100%;
            margin: 2rem auto;
            padding: 1.1rem;
            @media (min-width: 769px) {
                width: 67%;
                padding: 0;
            }
        }
    }

    .content-title {
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.625rem;
        color: #63666A;
        padding: 0 .5rem 0 .5rem;
        @media (min-width: 769px) {
            padding: 0;
            font-size: 1.5rem;
        }
    }

    .content-description {
        margin: 2rem 0 0 0;
        font-weight: 400;
        color: #707070;
    }

    .content-buttons {
        text-align: right;
        margin-top: 2rem;
        color: #3388B3;
        padding-right: 1.1rem;
        @media (min-width: 769px) {
            padding: 0;
        }
        .btn {
            margin-left: 2rem;
            font-weight: bold;
            font-size: 1.15rem;

            &-negative {
                cursor: pointer;
            }
        }

        a {
            font-weight: bold;
        }
    }
`;
