import React, { useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { IconCardStyled } from './CardStyles.js';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { ColorizedImage } from '../Page';
import { getClickableLink, setLinkFromApiAction } from '../../lib/util';

export default function IconCard(props) {
    const { analytic_name_prefix, page_id, item, visibleCount } = props;
    const { action, icon, footer } = item;
    const { type, web_redirect } = action;
    const { value } = footer;
    const { token } = useContext(GlobalContext);
    const iconCard = useRef();
    const needsTinted = icon.tint_color;
    const canvasSizes = {
        // needed in case icons need colorized
        small: 25,
        medium: 30,
        large: 40
    }
    const link = setLinkFromApiAction(action);

    function ConditionalLink({ children }) {
        // if web_redirect field has a page number hash in correct format, <Link/> component should be used for internal navigation
        // otherwise, web_redirect opens in separate tab to direct to external site
        let index, hash;
        const isHashRouting = web_redirect && (web_redirect.indexOf('#page-') !== -1 || web_redirect.indexOf('#classes-and-events') !== -1); // temporary until backend support for named pages is added
        if (isHashRouting) {
            index = web_redirect.indexOf('#');
            hash = web_redirect.substr(index, web_redirect.length - 1);
            return (<Link to={hash}
                          onClick={() => postAnalytics(token, {
                              "event_name": analytic_name_prefix + "_tap",
                              "event_data": { "page_id": page_id, ...item.action }
                          })}>
                {children}
            </Link>);
        } else {
            return (<a href={getClickableLink(link)}
                       target={type === "web" || web_redirect ? "_blank" : "_self"}
                       rel="noreferrer"
                       aria-label={value + ". Link opens in a new tab"}
                       onClick={() => postAnalytics(token, {
                           "event_name": analytic_name_prefix + "_tap",
                           "event_data": { "page_id": page_id, ...item.action }
                       })}>
                {children}
            </a>)
        }
    }

    function CardContent() {
        return (
            <>
                <div className="icon-container">
                    {!needsTinted ?
                        <img className="icon" src={icon.light} alt={icon.alt} title="Icon" aria-hidden="true"/> :
                        <ColorizedImage imageSrc={icon.light} colorHex={icon.tint_color.light}
                                        canvasSizes={canvasSizes}/>
                    }
                    <span className="card-text-desktop">{value}</span>
                </div>
                <div className="card-text-mobile">{value}</div>
            </>
        );
    }

    return (
        <IconCardStyled ref={iconCard} item={props.item} className={`max-visible-${visibleCount}`}>
            <ConditionalLink>
                <CardContent/>
            </ConditionalLink>
        </IconCardStyled>
    );
}