import React, { useState, useEffect } from 'react';
import { NavListStyled, NavSubListStyled, LanguageDropdownButton, MenuButton } from './HeaderStyles.js';
import { Link, useLocation } from 'react-router-dom';
import GetWindowSize from '../../hooks/GetWindowSize';
import i18n from '../../i18n';
import { useCookies } from "react-cookie";
import { toggleLanguage, getLanguageParam } from '../../hooks/languageUtils';

const languageParam = getLanguageParam();

// api will not support nav in this version but this should remain for a future version
const payload = [
    {
        'id': 1,
        'navbars': [
            {
                'id': 1,
                'name_key': 'mychart',
                'page_match_check': 'mychart',
                'hovericon': `<svg width="21" height="21" viewBox="0, 0, 400, 300" xmlns="http://www.w3.org/2000/svg"><g id="svgg">
                                <path id="path1" d="M138.767 69.384 L 138.767 128.429 77.137 128.429 L 15.507 128.429 15.507 192.644 L 15.507 256.859 77.137 256.859 L 138.767 256.859 138.767 318.489 L 138.767 380.119 200.398 380.119 L 262.028 380.119 262.028 318.489 L 262.028 256.859 323.658 256.859 L 385.288 256.859 385.288 192.644 L 385.288 128.429 323.658 128.429 L 262.028 128.429 262.028 69.384 L 262.028 10.338 200.398 10.338 L 138.767 10.338 138.767 69.384 " stroke="none" fill="#1474a4" fillRule="evenodd"></path><path id="path2" d="" stroke="none" fill="#c0100d0" fillRule="evenodd"></path><path id="path3" d="" stroke="none" fill="#c0100d0" fillRule="evenodd"></path><path id="path4" d="" stroke="none" fill="#c0100d0" fillRule="evenodd"></path></g>
                            </svg>`,
                'icon': `<svg width="21" height="21" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8484 7.36709V8.36709H13.8484H19.6115V13.1302H13.8484H12.8484V14.1302V19.8933H8.08536V14.1302V13.1302H7.08536H1.32227V8.36709H7.08536H8.08536V7.36709V1.604H12.8484V7.36709Z" fill="black" fill-opacity="0.01" stroke="#1175A7" stroke-width="2"/>
                        </svg>`,
                'link': 'https://livewell.aah.org/chart/Authentication/Login' + languageParam,
                'external': true,
                'hasDropdown': false,
            }, {
                'id': 2,
                'name_key': 'wellness',
                'page_match_check': 'Wellness',
                'hovericon': `<svg width="18" height="16" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="heart-solid_1_" data-name="heart-solid(1)" d="M18.046,33.163a5.342,5.342,0,0,0-7.289.531l-.77.793-.77-.793a5.341,5.341,0,0,0-7.289-.531,5.609,5.609,0,0,0-.387,8.121l7.558,7.8a1.225,1.225,0,0,0,1.769,0l7.558-7.8A5.605,5.605,0,0,0,18.046,33.163Z" transform="translate(0.012 -31.967)" fill="#1175a7"/>
                            </svg>`,
                'icon': `<svg width="18" height="17" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.2879 2.00344C16.0302 0.105159 12.5422 0.390289 10.3823 2.58933C8.22228 0.390289 4.73428 0.101249 2.47664 2.00344C-0.460619 4.4759 -0.0309688 8.50683 2.06261 10.6434L8.91362 17.6233C9.30421 18.0217 9.82761 18.2443 10.3823 18.2443C10.9408 18.2443 11.4603 18.0256 11.8509 17.6272L18.7019 10.6473C20.7916 8.51072 21.229 4.4798 18.2879 2.00343L18.2879 2.00344ZM17.3661 9.32707L10.5151 16.307C10.4213 16.4007 10.3432 16.4007 10.2495 16.307L3.39844 9.32707C1.97277 7.87406 1.68373 5.12428 3.68357 3.44083C5.20298 2.16359 7.54654 2.35498 9.01517 3.85095L10.3823 5.24537L11.7493 3.85095C13.2258 2.34717 15.5693 2.16358 17.0809 3.43692C19.0769 5.12038 18.78 7.88578 17.3661 9.32707Z" fill="#1175A7"/>
                        </svg>`,
                'link': '/wellness',
                'hasDropdown': true,
                'links': [
                    {
                        'name_key': 'meditations',
                        'link': '/wellness/guided-meditation',
                        'external': false
                    }, {
                        'name_key': 'recipes',
                        'link': 'https://www.ahchealthenews.com/recipes/#/',
                        'external': true
                    }, {
                        'name_key': 'health_e_news_articles',
                        'link': 'https://www.ahchealthenews.com/',
                        'external': true
                    }
                ]
            }, {
                'id': 3,
                'name_key': 'find_care',
                'page_match_check': 'find-health-care',
                'hovericon': `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.6644 17.2763L14.414 13.0259C14.3334 12.9453 14.2284 12.9033 14.1164 12.9033H13.6542C14.7571 11.6254 15.4258 9.96237 15.4258 8.14177C15.4258 4.11895 12.1662 0.859375 8.14341 0.859375C4.12059 0.859375 0.861023 4.11895 0.861023 8.14177C0.861023 12.1646 4.12059 15.4242 8.14341 15.4242C9.96401 15.4242 11.6271 14.7554 12.905 13.6526V14.1147C12.905 14.2268 12.9505 14.3318 13.0275 14.4123L17.2779 18.6627C17.4425 18.8273 17.7085 18.8273 17.8731 18.6627L18.6644 17.8715C18.8289 17.7069 18.8289 17.4408 18.6644 17.2763L18.6644 17.2763ZM8.14342 13.7436C5.0484 13.7436 2.54158 11.2368 2.54158 8.14176C2.54158 5.04673 5.0484 2.53991 8.14342 2.53991C11.2384 2.53991 13.7453 5.04673 13.7453 8.14176C13.7453 11.2368 11.2384 13.7436 8.14342 13.7436Z" fill="#1175A7"/>
                                <circle cx="8" cy="8" r="6" fill="#1175A7"/>
                            </svg>`,
                'icon': `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.6647 17.2769L14.4143 13.0265C14.3337 12.9459 14.2287 12.9039 14.1167 12.9039H13.6545C14.7574 11.626 15.4261 9.96298 15.4261 8.14238C15.4261 4.11956 12.1665 0.859985 8.14372 0.859985C4.1209 0.859985 0.861328 4.11956 0.861328 8.14238C0.861328 12.1652 4.1209 15.4248 8.14372 15.4248C9.96432 15.4248 11.6274 14.756 12.9053 13.6532V14.1153C12.9053 14.2274 12.9508 14.3324 13.0278 14.4129L17.2782 18.6633C17.4428 18.8279 17.7088 18.8279 17.8734 18.6633L18.6647 17.8721C18.8292 17.7075 18.8292 17.4414 18.6647 17.2769L18.6647 17.2769ZM8.14373 13.7442C5.04871 13.7442 2.54189 11.2374 2.54189 8.14237C2.54189 5.04735 5.04871 2.54053 8.14373 2.54053C11.2387 2.54053 13.7456 5.04735 13.7456 8.14237C13.7456 11.2374 11.2387 13.7442 8.14373 13.7442Z" fill="#1175A7"/>
                        </svg>`,
                'link': '/find-health-care',
                'hasDropdown': false
            }
        ]
    }
];

export default function Nav(props) {
    const windowSize = GetWindowSize();
    const [hoveringOn, setHoveringOn] = useState(null);
    const [isModalOpen] = props.modalState;
    const [showDropdown, setShowDropdown] = useState(false);
    const [cookies, setCookie] = useCookies(['selectedLanguage', 'languageCode']);
    const [toggleLanguageText, setToggleLanguageText] = useState(cookies.selectedLanguage || 'Español'); // Initial language set to English
    const [languageCode, setLanguageCode] = useState(cookies.languageCode || 'en');

    useEffect(() => {
        if (!isModalOpen) setHoveringOn(null);
    }, [isModalOpen]);

    const handleToggleLanguage = () => {
        toggleLanguage(toggleLanguageText, setToggleLanguageText, setLanguageCode, setCookie);
    };

    function onMenuClick(e, id) {
        e.stopPropagation();
        if (hoveringOn === id) {
            setHoveringOn(null);
        } else {
            setHoveringOn(id);
        }
        if (!e.currentTarget.classList.contains('plus') && !e.currentTarget.classList.contains('minus')) {
            setShowDropdown(!showDropdown);
        }
    }

    function resetMenu(e) {
        setShowDropdown(false);
        document.querySelector(".nav-list").classList.add("hide");
        document.querySelector(".hamburger").classList.remove("change");
    }

    function CreateNavBar() {
        let content = [];
        const { navbars } = payload[0];
        const location = useLocation();
        for (let i = 0; i < navbars.length; i++) {
            let matchesPage = location.pathname.toLowerCase().includes(navbars[i].page_match_check.toLowerCase());
            let name = i18n.t(navbars[i].name_key);
            if (navbars[i].hasDropdown) {
                let subContent = [];
                let length = navbars[i].links.length;
                for (let j = 0; j < length; j++) {
                    let linkName = i18n.t(navbars[i].links[j].name_key);
                    if (!navbars[i].links[j].external) {
                        subContent.push(
                            <li key={linkName}>
                                <Link className="sub-nav" onClick={resetMenu} to={navbars[i].links[j].link}>{linkName}</Link>
                            </li>
                        );
                    } else if (navbars[i].links[j].external) {
                        subContent.push(
                            <li key={linkName}>
                                <a className="sub-nav" onClick={resetMenu} href={ navbars[i].links[j].link } target="_blank" rel="noreferrer"
                                    aria-label={ linkName  + '. Link opens in a new tab' }>{ linkName }
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.1619 0.295044H1.85272C1.00099 0.295044 0.310547 0.985484 0.310547 1.83721V13.1464C0.310547 13.9982 1.00099 14.6886 1.85272 14.6886H13.1619C14.0137 14.6886 14.7041 13.9982 14.7041 13.1464V1.83721C14.7041 0.985484 14.0137 0.295044 13.1619 0.295044ZM12.9692 13.1464H2.04549C1.93903 13.1464 1.85272 13.0601 1.85272 12.9537V2.02997C1.85272 1.92351 1.93903 1.8372 2.04549 1.8372H12.9692C13.0756 1.8372 13.1619 1.92351 13.1619 2.02997V12.9537C13.1619 13.0601 13.0756 13.1464 12.9692 13.1464ZM11.2342 3.37937L6.86476 3.38403C6.65184 3.38403 6.47922 3.55666 6.47922 3.76957V4.66129C6.47922 4.87726 6.65663 5.0511 6.87257 5.04677L8.73605 4.97798L3.50782 10.2062C3.35727 10.3567 3.35727 10.6008 3.50782 10.7514L4.24774 11.4913C4.39829 11.6419 4.64241 11.6419 4.79296 11.4913L10.0212 6.26308L9.9524 8.12657C9.94803 8.3425 10.1219 8.51989 10.3379 8.51989H11.2296C11.4426 8.51989 11.6151 8.34726 11.6151 8.13435L11.6198 3.76491C11.6198 3.552 11.4471 3.37937 11.2342 3.37937H11.2342Z" fill="#1175A7"/>
                                    </svg>
                                </a>
                            </li>
                        );
                    }
                }
                content.push(
                    <li key={name} id={i} className="nav-item-main sub-menu-button"
                        onClick={(e) => onMenuClick(e, i)} 
                        onMouseEnter={ windowSize.width > 1024 ? () => setHoveringOn(i) : null} 
                        onMouseLeave={ windowSize.width > 1024 ? () => setHoveringOn(null) : null}
                    >
                        <Link onClick={resetMenu} to={ navbars[i].link } aria-label='Wellness. Internal link with sub menu.'>
                            { (hoveringOn === i || matchesPage) && (
                                <span dangerouslySetInnerHTML={{ __html: navbars[i].hovericon, }} />
                            )}
                            { hoveringOn !== i && !matchesPage && (
                                <span dangerouslySetInnerHTML={{ __html: navbars[i].icon, }} />
                            )}
                            { name }
                            { (hoveringOn === i || matchesPage) && windowSize.width > 1024 && (
                                <div className="border"></div>
                            )}
                        </Link>
                        { windowSize.width < 1024 && hoveringOn !== i && (<>
                                <MenuButton className="sub-menu-button plus" onClick={(e) => onMenuClick(e, i)}
                                    aria-label='Expand Wellness sub menu'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                        <path d="M1.10156 10.0004H17.4795H1.10156Z" fill="#1175A7"/>
                                        <path d="M1.10156 10.0004H17.4795" stroke="#1175A7" stroke-width="2" stroke-linecap="round"/>
                                        <path d="M9.29102 1.81067V18.1886V1.81067Z" fill="#1175A7"/>
                                        <path d="M9.29102 1.81067V18.1886" stroke="#1175A7" stroke-width="2" stroke-linecap="round"/>
                                    </svg>
                                </MenuButton>
                            </>)}
                            { (windowSize.width < 1024 && hoveringOn === i) && (<>
                                <MenuButton className="sub-menu-button minus" onClick={(e) => onMenuClick(e, i)}
                                    aria-label='Collapse Wellness sub menu'>
                                    <svg width="19" height="2" viewBox="0 0 19 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.10156 1.00098H17.4795H1.10156Z" fill="#1175A7"/>
                                        <path d="M1.10156 1.00098H17.4795" stroke="#1175A7" stroke-width="2" stroke-linecap="round"/>
                                    </svg>
                                </MenuButton>
                            </>)}
                        { hoveringOn === i && (
                            <NavSubListStyled>
                                <ul>
                                    { subContent }
                                </ul>
                            </NavSubListStyled>
                        )}
                    </li>
                );
            } else {
                content.push(
                    <li key={name} className="nav-item-main"
                        onMouseEnter={windowSize.width > 1024 ? () => setHoveringOn(i) : null} 
                        onMouseLeave={windowSize.width > 1024 ? () => setHoveringOn(null) : null}
                    >
                        {!navbars[i].external && <Link onClick={resetMenu} to={ navbars[i].link } target={navbars[i].external ? "_blank" : "_self"} rel="noreferrer"
                            aria-label={ name + '. Link opens in a new tab' }>
                            { (hoveringOn === i || matchesPage) && (
                                <span dangerouslySetInnerHTML={{ __html: navbars[i].hovericon, }} />
                            )}
                            { (hoveringOn !== i && !matchesPage) && (
                                <span dangerouslySetInnerHTML={{ __html: navbars[i].icon, }} />
                            )}
                            { name }
                            { (hoveringOn === i || matchesPage) && windowSize.width > 1024 && (
                                <div className="border"></div>
                            )}
                        </Link>}
                        {navbars[i].external && <a onClick={resetMenu} href={ navbars[i].link } target={navbars[i].external ? "_blank" : "_self"} rel="noreferrer"
                            aria-label={ name + '. Link opens in a new tab' }>
                            { (hoveringOn === i || matchesPage) && (
                                <span dangerouslySetInnerHTML={{ __html: navbars[i].hovericon, }} />
                            )}
                            { (hoveringOn !== i && !matchesPage) && (
                                <span dangerouslySetInnerHTML={{ __html: navbars[i].icon, }} />
                            )}
                            { name }
                            { (hoveringOn === i || matchesPage) && windowSize.width > 1024 && (
                                <div className="border"></div>
                            )}
                        </a>}
                    </li>
                );
            }
        }
        
        return (<>
            {content}
            <LanguageDropdownButton onClick={handleToggleLanguage}>{toggleLanguageText}</LanguageDropdownButton>
        </>)
    }

    return (
        <nav>
            <MenuButton onClick={onMenuClick}>
                <div className="hamburger" onClick={(e) => e.currentTarget.classList.toggle("change")} role="button" aria-label="Open navigation menu">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </MenuButton>
            <NavListStyled className={showDropdown ? "nav-list" : "nav-list hide"}>
                <CreateNavBar />
            </NavListStyled>
        </nav>
    );
}
