import React, { useEffect, useRef, useState } from 'react';
import { CarrouselStyled, CarrouselControlsStyled, IndicatorsContainerStyled } from './CarrouselStyles.js';
import CarrouselIndicator from './CarrouselIndicator';
import GetWindowSize from '../../hooks/GetWindowSize';

export default function Carrousel(props) {
    const visibleSlide = useRef(1);
    const carrousel = useRef();
    const slidesContainer = useRef();
    const scrollLeftContainer = useRef();
    const scrollRightContainer = useRef();
    const indicatorContainer = useRef();
    const carrouselInterval = useRef(null);
    const numIndicators = useRef(Math.ceil(props.children.length / parseInt(props.visibleCount, 10)));
    const isScrolling = useRef(false);
    const min = 1;
    const max = props.max;
    const visibleCount = parseInt(props.visibleCount, 10);
    const windowSize = GetWindowSize();
    const [maskImageClass, setMaskImageClass] = useState();

    useEffect(() => {
        // add when carrousel renders, need to add active class to first indicator if indicators are shown
        if (indicatorContainer.current.children.length) {
            indicatorContainer.current.children[0].children[0].classList.add("active");
        }

        // if scroll_time exists, begin updating visible slide at defined intervals
        if (props.scroll_time && slidesContainer.current.children?.length) {
            carrouselInterval.current = startAutoScroll();
        }

        // for manual image scroller, user should not be able to scroll by touch screen or mouse wheel
        if (props.type === "manual-image-scroller") {
            disableHorizontalScroll();
        }

        return () => {
            if (carrouselInterval.current) {
                stopAutoScroll();
            }
        }
    }, []);

    useEffect(() => {
        let cssClass;
        const prevClasses = getPreviousCSSClasses();
        if (windowSize.width > 1200) {
            cssClass = `mask-image ${prevClasses}`;
            setMaskImageClass(cssClass);
        } else {
            if (max <= visibleCount) {
                cssClass = prevClasses;
            } else {
                cssClass = `mask-image-right-only ${prevClasses}`;                
            }
            setMaskImageClass(cssClass);            
        }

        // get previously existing classes that are added outside of carousel component as these will need to be reapplied on window resize
        // added to fix bug where meditation modal styling gets messed up when window is resized and hits break point above
        function getPreviousCSSClasses() {
            let ignoreClasses = ['slides-container', 'mask-image', 'mask-image-right-only'];
            let prevClasses = [];
            let prevClassesFiltered = [];
            let classString;
            slidesContainer.current.classList.forEach(prevClass => prevClasses.push(prevClass));
            prevClassesFiltered = prevClasses.filter(cssClass => ignoreClasses.indexOf(cssClass) === -1);
            classString = prevClassesFiltered.join(" ");
            return classString;
        }
        
        // if window size meets minimum desktop version, and user's primary input is mouse and supports hover, user should not be able to scroll through carousel by mouse wheel or trackpad
        const isMinimumDesktopWidth = windowSize.width > 768;
        const hasMouseAndSupportsHover = window.matchMedia("(pointer: fine) and (hover: hover)").matches;        
        if (isMinimumDesktopWidth && hasMouseAndSupportsHover) {
            disableHorizontalScroll();
        }

    }, [windowSize]);

    /**
     * scroll carrousel when user clicks on left or right controls
     * @param {object} e event
     */
    function scrollCarrousel(e) {

        if (isScrolling.current) {
            // user clicked while container is still scrolling, don't do anything
            return;
        }

        // stop carrousel autoscroll if currently running
        if (carrouselInterval.current) {
            stopAutoScroll();
        }

        let scrollRight = false;
        let scrollTo;
        if (e.currentTarget.classList.contains('scroll-right-container')) {
            scrollRight = true;
        }

        const scrollContainer = e.currentTarget.parentElement.previousSibling;    

        // scroll the width of the slide (plus margin) times number of slides visible
        const slideWidth = scrollContainer.firstChild.getBoundingClientRect().width + 32;
        const scrollDistance = slideWidth * visibleCount;
        const currentPosition = scrollContainer.scrollLeft;
        if (scrollRight) {
            scrollTo = currentPosition + scrollDistance;
        } else {
            scrollTo = currentPosition - scrollDistance
        }
        scrollContainer.scroll({
            top: 0,
            left: scrollTo,
            behavior: 'smooth'
        });
        
        // update visibleSlide reference
        updateVisibleSlide(scrollRight);

        // this prevents user from interrupting scroll by clicking twice
        // after a half a second, allow user to click again and update indicator styling
        isScrolling.current = true;
        setTimeout(() => {
            isScrolling.current = false;
            styleIndicatorsOnScroll(scrollRight, false);
        }, 500);

        updateMaskImageClass();
    }

    /**
     * scroll the carrousel when user clicks on an indicator bubble
     * @param {object} e event
     */
    function scrollTo(e) {
        // stop carrousel autoscroll if currently running
        if (carrouselInterval.current) {
            stopAutoScroll();
        }
        
        const scrollToNumber = parseInt(e.currentTarget.dataset.indicatorNumber, 10);
        const slideWidth = slidesContainer.current.firstChild.getBoundingClientRect().width + 20; // width of one slide plus padding
        const scrollDistance = slideWidth * visibleCount; // total distance to scroll - slide width times amount visible
        let scrollTo;
        if (scrollToNumber === visibleSlide.current) {
            // no action needed if clicked same slide as currently shown
            return -1;
        } else {
            // define position to scroll to as width of all visible slides times the indicator number clicked (minus 1 to offset visible slide)
            scrollTo = scrollDistance * (scrollToNumber - 1);
        }
        slidesContainer.current.scroll({
            top: 0,
            left: scrollTo,
            behavior: 'smooth'
        });        

        visibleSlide.current = scrollToNumber;

        // update active indicator styling
        styleIndicatorsOnClick(e);
    }

    /**
     * scroll carrousel at interval when autoscroll has been started
     */
    function scrollCarrouselAtInterval() {
        let scrollRight = false;
        let scrollTo;
        const max = props.max;
        
        if (visibleSlide.current < max) {
            scrollRight = true;
        }

        // need to scroll the width of the slide (plus margin) times number of slides visible
        const slideWidth = slidesContainer.current.firstChild.getBoundingClientRect().width + 20;
        const scrollDistance = slideWidth * visibleCount;
        const currentPosition = slidesContainer.current.scrollLeft;
        if (scrollRight) {
            scrollTo = currentPosition + scrollDistance;
        } else {
            scrollTo = 0;
        }

        slidesContainer.current.scroll({
            top: 0,
            left: scrollTo,
            behavior: 'smooth'
        });

        updateVisibleSlide(scrollRight, true);

        // update active indicator styling
        styleIndicatorsOnScroll(scrollRight, true);
    }

    /**
     * render carousel indicators by number ref
     * @returns array of jsx components
     */
    function renderIndicators() {
        let content = [];
        let i = 0;
        while (i < numIndicators.current) {
            content.push(<CarrouselIndicator key={i} indicatorNumber={i + 1} scrollTo={scrollTo} />);
            i++;
        }
        return content;
    }

    /**
     * 
     * @param {boolean} isIncrement if true, carrousel scrolled to right, false if to the left
     * @param {*} isAutoScroll true if being updated via autoscroll
     */
    function updateVisibleSlide(isIncrement, isAutoScroll) {
        const offset = visibleCount - 1; // used to caclulate how many additional slides to show after first in view
        if (isIncrement) {
            // if more slides exist off to right after those currently visible on screen, keep increasing
            // else set current visibleSlide reference to first visible
            if (visibleSlide.current + offset < max - offset) {
                visibleSlide.current = visibleSlide.current + visibleCount;            
            } else {
                visibleSlide.current = max - offset;
            }    
        } else {
            // scrolling to left, do opposite of scrolling right
            // check isAutoScroll here - if scroll occurs via timer, need to jump down to first slide after end of carrousel is reached
            if (visibleSlide.current - offset > min + offset & !isAutoScroll) {
                visibleSlide.current = visibleSlide.current - visibleCount;            
            } else {
                visibleSlide.current = min;
            }
        }        
    }

    /**
     * remove css classes to hide controls
     * should only be removed if addtional slides exist in respective direction
     * @param {object} e event
     */
    function showControls(e) {
        e.stopPropagation();
        if (props.showControls) {
            if (visibleSlide.current > min) {
                scrollLeftContainer.current.classList.remove("hide");
            }
            if (visibleSlide.current < max - visibleCount + 1) {
                scrollRightContainer.current.classList.remove("hide");
            }
        }
        if (!props.showIndicator) {
            indicatorContainer.current.classList.add('show-on-hover');
        }
    }

    /**
     * add css classes to hide controls
     * should only be added if no addtional slides exist in respective direction
     * @param {object} e event
     */
    function hideControls(e) {
        e.stopPropagation();
        if (props.showControls) {
            scrollLeftContainer.current.classList.add("hide");
            scrollRightContainer.current.classList.add("hide");
        }
        if (!props.showIndicator) {
            indicatorContainer.current.classList.remove('show-on-hover');
        }
    }

    /**
     * update indicator bubble styling when scrolled left or right by user
     * @param {boolena} scrollRight if true carousel is scrolling right, false left
     * @param {boolean} isAutoScroll if true, function was calling when autoscrolling
     */
    function styleIndicatorsOnScroll(scrollRight, isAutoScroll) {
        if (indicatorContainer.current) {
            let indicators = Array.from(indicatorContainer.current.children);
            const isActive = (item) => item.children[0].classList.contains("active");
            let activeIndex = indicators.findIndex(isActive);
            if (scrollRight && activeIndex + 1 < indicators.length) {
                indicators[activeIndex].children[0].classList.remove("active");
                indicators[++activeIndex].children[0].classList.add("active");
            } else if (!scrollRight && activeIndex > 0 && !isAutoScroll) {
                indicators[activeIndex].children[0].classList.remove("active");
                indicators[--activeIndex].children[0].classList.add("active");
            } else if (!scrollRight && isAutoScroll) {
                indicators[activeIndex].children[0].classList.remove("active");
                indicators[0].children[0].classList.add("active");
            } else {
                return -1;
            }
        }
    }

    /**
     * update indicator bubble styling when user clicks on bubble (only applies when carousel is used for Hero)
     * @param {Event} e event
     */
    function styleIndicatorsOnClick(e) {
        let indicators = Array.from(indicatorContainer.current.children);
        const isActive = (item) => item.children[0].classList.contains("active");
        let activeIndex = indicators.findIndex(isActive);
        let clickedIndex = parseInt(e.currentTarget.dataset.indicatorNumber, 10) - 1;
        if (indicatorContainer.current) {            
            indicators[activeIndex].children[0].classList.remove("active");
            indicators[clickedIndex].children[0].classList.add("active");
        }
    }

    /**
     * begin auto scrolling carrousel
     * @returns auto scroll interval
     */
    function startAutoScroll() {
        return setInterval(scrollCarrouselAtInterval, 1000 * parseInt(props.scroll_time));
    }

    /**
     * clear the auto scroll interval
     */
    function stopAutoScroll() {
        clearInterval(carrouselInterval.current);
    }

    function updateMaskImageClass() {
        if (windowSize.width) {
            if (windowSize.width > 1200) {                
                setMaskImageClass('mask-image');
            } else if (visibleSlide.current > min && visibleSlide.current < max) {
                setMaskImageClass('mask-image');
            } else if (visibleSlide.current < max) {
                setMaskImageClass('mask-image-right-only');
            }
        }
    }

    function preventTextHighlight(e) {
        if (e.detail > 1) {
            e.preventDefault();
        }
    }

    function disableHorizontalScroll() {
        // mobile disable horizontal scrolling
        let firstClientX;
        slidesContainer.current.addEventListener('touchstart', (e) => {
            firstClientX = e.touches[0].clientX
        });
        slidesContainer.current.addEventListener('touchmove', (e) => {
            const minValue = 5; // min threshold
            let clientX = e.touches[0].clientX - firstClientX;

            // swiping horizontally.
            if(Math.abs(clientX) > minValue){
               e.preventDefault();
            }
        });
    }

    return(
        <CarrouselStyled className={`${props.type === "manual-image-scroller" ? "manual-image-scroller" : ""}`} ref={carrousel} onMouseOver={showControls} onMouseOut={hideControls}>           

            <div ref={slidesContainer} className={`slides-container ${(maskImageClass) ? maskImageClass : ''}`}>
                    {props.children}             
            </div>

            {props.showControls &&
            <CarrouselControlsStyled>
                <div ref={scrollLeftContainer} onClick={scrollCarrousel} onMouseDown={preventTextHighlight} className="scroll-left-container hide">
                    <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.448 0.194995L14.5462 1.29322C14.8061 1.55315 14.8061 1.9746 14.5462 2.23458L4.52319 12.2816L14.5462 22.3286C14.8061 22.5885 14.8061 23.01 14.5462 23.27L13.4479 24.3682C13.188 24.6281 12.7665 24.6281 12.5066 24.3682L0.890923 12.7522C0.630962 12.4923 0.630962 12.0708 0.890923 11.8108L12.5066 0.194985C12.7666 -0.064995 13.1881 -0.064995 13.448 0.194985V0.194995Z" fill="#0F0F0F"/>
                    </svg>
                </div>
                <div ref={scrollRightContainer} onClick={scrollCarrousel} onMouseDown={preventTextHighlight} className="scroll-right-container hide">
                    <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.55213 0.194995L0.453888 1.29322C0.193958 1.55315 0.193958 1.9746 0.453888 2.23458L10.4769 12.2816L0.453938 22.3286C0.194038 22.5885 0.194038 23.01 0.453938 23.27L1.55216 24.3682C1.81209 24.6281 2.23356 24.6281 2.49354 24.3682L14.1092 12.7522C14.3691 12.4923 14.3691 12.0708 14.1092 11.8108L2.49346 0.194985C2.2335 -0.064995 1.81204 -0.064995 1.55213 0.194985V0.194995Z" fill="#0F0F0F"/>
                    </svg>
                </div>
            </CarrouselControlsStyled>
            }

            
            
            {props.showIndicator 
                ? <IndicatorsContainerStyled ref={indicatorContainer} className={`hero ${numIndicators.current < 2 ? 'show-never' : ''}`}>
                    {renderIndicators()}
                </IndicatorsContainerStyled>
                : <IndicatorsContainerStyled ref={indicatorContainer} onMouseOver={hideControls} className={`section ${numIndicators.current < 2 ? 'show-never' : ''}`}>
                    {renderIndicators()}
                </IndicatorsContainerStyled>
            }
        </CarrouselStyled>
    );
}