import React, { useState, useEffect, useContext } from 'react';
import { Page, PageSection } from '../Page';
import { PageHeader } from '../Page';
import CurrentPageNav from '../../hooks/CurrentPageNav';
import Carrousel from '../Carrousel';
import MeditationsCard from './MeditationsCard';
import { postAnalytics, getMeditationsData, getBgSoundData } from '../../common/api';
import GlobalContext from '../../GlobalContext';
import { SEO } from "../Page";
import ErrorPage from "../Error/ErrorPage";
import LoadingIndicator from '../Loading/LoadingIndicator';
import i18n from '../../i18n';

export default function Meditations(props) {
    const [meditationsData, setMeditationsData] = useState(false);
    const [bgSoundData, setBgSoundData] = useState(false);
    const { token } = useContext(GlobalContext);
    const [apiStatus, setApiStatus] = useState({success: true, httpCode: ""});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (token) {
            setData();
            setBgSound();
        }

        async function setData() {
            try {
                setIsLoading(true);
                const res = await getMeditationsData(token);
                setIsLoading(false);
                setMeditationsData(res.data);
                postAnalytics(token, {
                    "event_name": "meditation_categories_view"
                });
            } catch (error) {
                setIsLoading(false);
                setApiStatus({
                    success: false,
                    httpCode: error.http_code ? error.http_code : "500"
                });
            }
        }

        async function setBgSound() {
            const res = await getBgSoundData(token);
            setBgSoundData(res);
        }
    }, [token]);    

    return (
        <>
            <SEO title="Guided Meditation | LiveWell | Advocate Aurora Health"
                    description="LiveWell offers guided meditation to help you ease stress, relieve anxiety, improve sleep and increase mindfulness - anytime and anywhere."
                    keywords="meditation, guided meditation, meditation music, anxiety relief meditation" />
            {apiStatus.success && !isLoading && <Page>                
                <CurrentPageNav {...props}/>
                <div>
                <PageHeader title={i18n.t('guided_meditations')} description={i18n.t('guided_meditations_description')} />
                {/* h2 tags below for SEO only */}
                <h2 style={{fontSize: '1px', visibility: 'hidden'}}>how to meditate</h2>
                <h2 style={{fontSize: '1px', visibility: 'hidden'}}>benefits of meditation</h2>
                </div>
                {meditationsData && meditationsData.map((page) => {
                    return <PageSection id={page.id} heading={page.name} showAll="true" link={`/wellness/guided-meditation/${page.slug}`} subheading={page.description}>
                        <Carrousel section="meditations" visibleCount="4" max={page.exercises.length} showControls="true">
                            {page.exercises.map((item, i) => {
                                return <MeditationsCard key={item.id} id={`meditations-slide-${i+1}`} visibleCount="4" meditation={item} sounds={bgSoundData} />
                            })}
                        </Carrousel>
                    </PageSection>
                })}
            </Page>}
            
            {!apiStatus.success && <ErrorPage httpCode={apiStatus.httpCode} />}

            {isLoading && <Page><LoadingIndicator /></Page>}
        </>
    );
}