import React from 'react';
import styled from 'styled-components';

const LoadingIndicatorStyled = styled.div`
width: 100%;
height: 100%;
margin: 0 auto;
    .lds-ring {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 80px;
        height: 80px;
        margin: 0 auto;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #efefef transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default function LoadingIndicator(props) {
    return (
        <LoadingIndicatorStyled>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </LoadingIndicatorStyled>
    );
}