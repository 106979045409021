import { useState } from "react";
import { getStateSelectorData } from '../common/api';

export default function UseStateSelector() {
    const [stateSelector, setStateSelector] = useState({
        isOpen: false,
        data: {},
        openModal: openModal,
        closeModal: closeModal
    });

    async function openModal(token, link) {
        const res = await getStateSelectorData(token, link);
        setStateSelector((prev) => {
            return {
                ...prev,
                isOpen: true,
                data: res
            }
        });
    }

    function closeModal() {
        setStateSelector(prev => {
            return {
                ...prev,
                isOpen: false
            }
        });
    }

    return [stateSelector, setStateSelector] ;
}