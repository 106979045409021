import React, { useState, useEffect, useContext } from 'react';
import { Page } from '../Page';
import { ImageGrid } from './MeditationStyles.js';
import CurrentPageNav from '../../hooks/CurrentPageNav';
import MeditationsCard from './MeditationsCard';
import { PageHeader } from './../Page';
import { useParams } from "react-router-dom";
import { postAnalytics, getMeditationsDataByCategory, getBgSoundData } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { SEO } from "../Page";
import ErrorPage from "../Error/ErrorPage";
import LoadingIndicator from '../Loading/LoadingIndicator';

export default function ShowAllMeditations(props) {
    const { slug } = useParams();
    const [meditationsData, setMeditationsData] = useState(false);
    const [bgSoundData, setBgSoundData] = useState(false);
    const [apiStatus, setApiStatus] = useState({success: true, httpCode: ""});
    const [isLoading, setIsLoading] = useState(true);
    const { token } = useContext(GlobalContext);

    useEffect(() => {
        if (token) {
            setData();
            setBgSound();
        }

        async function setData() {
            try {
                setIsLoading(true);
                const res = await getMeditationsDataByCategory(token, slug);
                setIsLoading(false);
                if (res.success === false) {
                    setApiStatus({
                        success: res.success,
                        httpCode: res.http_code
                    });
                } else {                    
                    setMeditationsData(res);
                    postAnalytics(token, {
                        "event_name": "meditation_category_view"
                    });
                }
            } catch (error) {
                setIsLoading(false);
                setApiStatus({
                    success: false,
                    httpCode: error.http_code ? error.http_code : "500"
                });
            }
        }

        async function setBgSound() {
            const res = await getBgSoundData(token);
            setBgSoundData(res);
        }
    }, [token]);

    return (
        <>
            {apiStatus.success && !isLoading && <Page>
                <SEO title={`${meditationsData.name} | LiveWell | Advocate Aurora Health`} />
                <CurrentPageNav {...props} title={meditationsData.name}/>
                <PageHeader title={meditationsData ? meditationsData.name : ''} description={meditationsData.description} />
                <ImageGrid>
                    {meditationsData && meditationsData.exercises.map((item, i) => {
                        return <MeditationsCard 
                            meditation={item}
                            showAll={true}
                            sounds={bgSoundData}
                        />
                    })}
                </ImageGrid>
            </Page>}
            
            {!apiStatus.success && <ErrorPage httpCode={apiStatus.httpCode} />}

            {isLoading && <Page><LoadingIndicator /></Page>}
        </>
    );
}