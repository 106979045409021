import React from 'react';
import { ContainerStyled } from './PageStyles.js';

export default function Container(props) {
    return (
        <main>
            <ContainerStyled className={props.noTopMargin ? 'no-top-margin' : ''}>
                {props.children}
            </ContainerStyled>
        </main>
    );
}