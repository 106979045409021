import React from "react"
import { Helmet } from "react-helmet"

export default function SEO(props) {
    const { title, description, keywords } = props;
	return (
		<Helmet>
			{ description && <meta name="description" content={description} /> }
			{ keywords && <meta name="keywords" content={keywords} /> }
			{ title && <title>{title}</title> }
		</Helmet>
	);
}