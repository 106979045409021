import React, { useEffect } from 'react';
import { MappedComponent } from "../PageBuilder";
import LoadingIndicator from '../Loading/LoadingIndicator';
import ErrorPage from "../Error/ErrorPage";

export default function DynamicPage(props) {
    const { data, apiStatus } = props;

    return (
        <>
        {apiStatus.success && 
            <div>
                {!data && <div style={{marginTop: '8rem'}}>
                    <LoadingIndicator/>
                </div>}
                {data && data.content.map((item, i) => {
                    return <MappedComponent
                        key={i} 
                        data={item}
                    />
                })}
            </div>
        }

        {!apiStatus.success &&
            <ErrorPage httpCode={apiStatus.httpCode} />
        }
        </> 
    );
}