import React, { useContext, useCallback } from 'react';
import { StateSelectorModalStyled } from './PageStyles';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { getClickableLink } from "../../lib/util";
import TitleAnnouncer from "../Page/TitleAnnouncer";

export default function StateSelectorModal(props) {
    const [stateSelector] = props.stateSelector;
    const { token } = useContext(GlobalContext);
    const items = stateSelector.data?.content[0]?.children;

    const focusedRef = useCallback(node => {
        if (node !== null) {
          node.focus();
        }
      }, []);

    const selectCard = (e, item) => { 
        postAnalytics(token, {
            "event_name": props.analytic_name_prefix + "_tap",
            "event_data": {"page_id": props.page_id, ...item.action}
        });
        const link = getClickableLink(item.action.url)
        window.open(link);
    }

    // this was added for accessibility using voice navigation
    // numbers weren't showing up correctly when a bunch of html was nested inside of anchor tags
    function goToLink(e) {
        let link = document.querySelector('#link');
        link.click();
    }

    return(
        <StateSelectorModalStyled onClick={stateSelector.closeModal}>
            <div className="state-selector-container" onClick={e => e.stopPropagation()} role="dialog" aria-modal="true">
            <span className="close-btn" onClick={stateSelector.closeModal} role="button" aria-label="Close state selector overlay">
                <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.02399 1.5L22.024 21.5" stroke="#626262" stroke-width="3" stroke-linecap="round"/>
                    <path d="M22.024 1.5L2.02399 21.5" stroke="#626262" stroke-width="3" stroke-linecap="round"/>
                </svg>
            </span>
            <div className="content">
                <div ref={focusedRef} className="title-text" tabIndex="-1">
                    <TitleAnnouncer title={items[0]?.properties?.text?.value} isAssertive={true} />
                </div>                
                <div className="cards">
                    {
                        stateSelector.data.content[0].children[1].properties.children.map((item) => {
                            return <div className="state-card">
                                <div className="card-content"  target="_blank" role="button" rel="noreferrer" aria-label={item.properties.title + " - link opens in a new tab"}
                                    onClick={(e) => selectCard(e, item)}  tabIndex="0" onKeyDown={(e) => {
                                        if ((e.key === "Enter")) {
                                            selectCard(e, item)
                                        }}}>
                                    <img src={item.properties.image.light} alt="State icon" title="State icon"/>
                                    <div className="card-text">
                                        {item.properties.title}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            </div>
        </StateSelectorModalStyled>
    );
}