import React from 'react';

import Container from './Container';

export default function Page(props) {
    return (
        <Container {...props}>
            {props.children}
        </Container>    
    );
}