import React from 'react';
import Carrousel from '../../components/Carrousel';
import { GraphicTextContentCard } from '../../components/Cards';
import { PageSection } from '../../components/Page';
import { useCookies } from "react-cookie";

export default function GraphicTextContentCards(props) {
    const [cookies] = useCookies(['viewedMeals']);
    const { data } = props;
    const items = data.data;

    function recentizedViewedCards() {
        let recentContent = new Array(cookies.viewedMeals?.length);
        let content = [];
        items.map((item, i) => {
            const id = item.action?.link?.replace("meal_plans/", "");
            const isVisited = cookies.viewedMeals?.includes(id);
            if (isVisited) {
                const index = cookies.viewedMeals.indexOf(id);
                recentContent[index] = <GraphicTextContentCard {...props} key={i} image={item.image.light} title={item.title.value} description={item.content.value} link={'/wellness/meal-plans/' + id} carouselView={true} />
            } else {
                content.push(<GraphicTextContentCard {...props} key={i} image={item.image.light} title={item.title.value} description={item.content.value} link={'/wellness/meal-plans/' + id} carouselView={true} />)
            }
            return;
        })
        return recentContent.concat(content);
    }
    
    return (
        <PageSection heading={data.top_section?.title?.value} 
                     subheading={data.top_section?.subtitle?.value}
                     linkText={data.bottom_section?.button?.text?.value} 
                     link={data.bottom_section?.button?.text?.value}>
            <Carrousel visibleCount="3" max={data.data.length} showControls="true">
                {/* Updated recentizedViewedCards to not be a React component because the number of direct children
                     of carousel need to be same as number of slides for certain functionalities to work right */}
                {recentizedViewedCards()}
            </Carrousel>
        </PageSection>
    );
}