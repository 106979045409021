import styled from 'styled-components';

export const ErrorPageStyled = styled.div`
    margin: 2rem 0 4rem 0;
    text-align: center;
    h2 {
        font-weight: 700;
        font-size: 1.75rem;
        color: #535353;
    }
    h3 {
        margin-bottom: 4rem;
        font-size: 1.125rem;
        color: #707070;
    }
`;