import { useState } from "react";
import { getEmergencyAlerts } from '../common/api';

export default function UseStateSelector() {

    const [alertData, setAlertData] = useState({
        showAlert: false,
        data: null,
        setAlert: setAlert,
        closeAlert: closeAlert
    });


    function closeAlert() {
        setAlertData(prev => {
            return {
                ...prev,
                showAlert: false
            }
        });
    }

    async function setAlert(token) {
        const res = await getEmergencyAlerts(token);
        setAlertData(prev => {
            return {
                ...prev,
                showAlert: res.show_alert,
                data: res
            }
        });
    }

    return [alertData, setAlertData];
}