import React, { useContext } from 'react';
import { MultiBlogFeedCardStyled } from './CardStyles.js';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { getClickableLink } from "../../lib/util";

export default function MultiBlogFeedCard(props) {
    const { token } = useContext(GlobalContext);

    let aspectRatio;
    switch (props.visibleCount) {
        case '2':
            aspectRatio = '16-9';
            break;
        case '3':
            aspectRatio = '3-2';
            break;
        default:
            aspectRatio = '';
    }

    return (
        <MultiBlogFeedCardStyled className={`max-visible-${props.visibleCount}`}
            onClick={() => postAnalytics(token, {
                "event_name": props.analytic_name_prefix + "_tap",
                "event_data": {
                    "page_id": props.page_id,
                    "type": "web",
                    "url": props.link,
                    "launch_type": "external"
                }
            })}
        >
            <img src={props.imageUrl} alt={props.title} title="Thumbnail image" className={(aspectRatio) ? `ar-${aspectRatio}` : ''}
                 aria-label={props.title + ". Link opens in a new tab"} />
            <a href={getClickableLink(props.link)} target="_blank" rel="noreferrer"
               aria-label={props.title + ". Link opens in a new tab"}>{props.title}</a>
            {props.description && 
                <span className="description">{props.description}</span>
            }
        </MultiBlogFeedCardStyled>
    );
}