import React, { useState, useEffect, useContext } from 'react';
import { Page } from '../Page';
import CurrentPageNav from '../../hooks/CurrentPageNav';
import { StateSelectorModal } from '../Page';
import { MappedComponent } from '../PageBuilder';
import { postAnalytics, getFindCareData } from "../../common/api";
import UseStateSelector from '../../hooks/UseStateSelector';
import { SEO } from "../Page";
import ErrorPage from "../Error/ErrorPage";
import LoadingIndicator from '../Loading/LoadingIndicator';
import GlobalContext from '../../GlobalContext';

export default function FindCare(props) {
    const [findCareData, setFindCareData] = useState(false);
    const [stateSelector, setStateSelector] = UseStateSelector();
    const { token } = useContext(GlobalContext);
    const [apiStatus, setApiStatus] = useState({success: true, httpCode: ""});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (token) {
            setData();
        }

        async function setData() {
            try {
                setIsLoading(true);
                const data = await getFindCareData(token);
                setIsLoading(false);
                setFindCareData(data);
                postAnalytics(token, {
                    "event_name": data.analytic_name_prefix + "_view",
                    "event_data": {
                        "page_id": data.analytic_additional_params.page_id
                    }
                });
            } catch (error) {
                setIsLoading(false);
                setApiStatus({
                    success: false,
                    httpCode: error.http_code ? error.http_code : "500"
                });
            }
        }
    }, [token]);

    return (
        <>
            <SEO title="Find Health Care | LiveWell | Advocate Aurora Health"
                 description="Whether it's meeting a new primary care doctor or starting a telehealth virtual visit, LiveWell can help you find the right type of health care for your needs." />
            {apiStatus.success && !isLoading && <Page>
                <CurrentPageNav {...props}/>
                {findCareData && findCareData.content.map((item) => {
                    return <MappedComponent 
                        analytic_name_prefix={findCareData.analytic_name_prefix} 
                        page_id={findCareData.analytic_additional_params.page_id} 
                        type={item.type} 
                        data={item} 
                        token={token} 
                        title={findCareData.title} 
                        stateSelector={[stateSelector, setStateSelector]} />
                })}            
                { stateSelector.isOpen &&
                    <StateSelectorModal
                        analytic_name_prefix={findCareData.analytic_name_prefix} 
                        page_id={findCareData.analytic_additional_params.page_id}  
                        stateSelector={[stateSelector, setStateSelector]} />
                }                
            </Page>}
            
            {!apiStatus.success && <ErrorPage httpCode={apiStatus.httpCode} />}

            {isLoading && <Page><LoadingIndicator /></Page>}
            
        </>
    );
}