import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { MeditationCardStyled, MeditationCardStyledAlt, Modal, MeditationGridStyledAlt } from './MeditationStyles.js';
import UseAudio from '../../hooks/UseAudio';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';

export default function MeditationsCard(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [soundName, setSoundName] = useState('None');
    const [soundUrl, setSoundUrl] = useState('');
    const [isMeditating, setIsMeditating] = useState(false);
    const [audioElement, audioProps] = UseAudio(props.meditation.url, soundName, props.sounds);
    const themeColor = props.meditation.dominant_color;
    const { token } = useContext(GlobalContext);
    const altText = props.meditation.image_alt_text || 'meditation';

    useEffect(() => {
        if (isMeditating) {
            // force devices to play audio when isMeditating sets to true
            const meditationEl = audioElement.props.children[0].ref.current;
            meditationEl.play();   
        }
    }, [isMeditating]);

    function handlePressEnter(e) {        
        if (e.key === "Enter") {
            openModal(e)
        }
    }

    const playButtonRef = useCallback(node => {
        if (node !== null) {
          node.focus();
        }
      }, []);

    function handleOutsideClick(e) {
        let slidesContainer;
        if (e.currentTarget.classList.contains("modal-close")) {
            slidesContainer = e.currentTarget.parentElement.parentElement.parentElement;
        } else if (e.currentTarget.firstChild.classList.contains("modal-container")) {
            slidesContainer = e.currentTarget.parentElement;
        }
        if (slidesContainer) {
            slidesContainer.classList.remove("modal-open");
        }
        setIsOpen(false);
        setIsMeditating(false);
        // this handles the body vs. modal scroll
        document.body.style.overflow = 'unset';
        if (!!navigator.platform.match(/iPhone/)) {
            document.body.style.position = 'relative';
        }
    }

    function changeSound(e) {
        setSoundName(e.target.innerHTML);
        setSoundUrl('');
        props.sounds.filter(function(sound){
            if (sound.name === e.target.innerHTML) {
                setSoundUrl(sound.audio);
            }
        });
    }

    function RemainingTime({ currentTime, isSeeking, duration }) {
        return (
            <div className="modal-timer">
                <>{isSeeking ? "buffering..." : formatTime(duration - currentTime)}</>
            </div>
        );
    }

    function formatTime(seconds) {
        return (
            [
                Math.floor(seconds / 60), // minutes
                Math.floor(seconds % 60), // remaining seconds
            ]
            .map(x => x.toString())
            .map(x => (x.length === 1 ? `0${x}` : x))
            .join(":")
        );
    }

    function openModal(e, targetElement = null) {
        let slidesContainer = '';
        slidesContainer = e.currentTarget.parentElement;
        slidesContainer.classList.add("modal-open");
        let image = document.createElement("img");
        image.src = props.meditation.image;
        setIsOpen(!isOpen);
        // this handles the body vs. modal scroll
        document.body.style.overflow = 'hidden';
        if (!!navigator.platform.match(/iPhone/)) {
            document.body.style.position = 'fixed';
        }

        postAnalytics(token, {
            "event_name": "meditation_exercise_tap",
            "event_data": {
                "id": props.meditation.id,
                "type": "web",
                "title": props.meditation.title,
                "description": props.meditation.description,
                "audio_url": props.meditation.url,
                "thumbnail_url": props.meditation.image,
                "image_url": props.meditation.image,
                "show_background_tracks": props.meditation.show_background_tracks
            }
        });
        postAnalytics(token, {
            "event_name": "meditation_exercise_view"
        });
    };

    function startMeditation () {
        postAnalytics(token, {
            "event_name": "meditation_exercise_start_tap",
            "event_data": {
                "id": props.meditation.id,
                "type": "web",
                "title": props.meditation.title,
                "description": props.meditation.description,
                "audio_url": props.meditation.url,
                "thumbnail_url": props.meditation.image,
                "image_url": props.meditation.image,
                "show_background_tracks": props.meditation.show_background_tracks,
                "background_title": soundName,
                "background_url": soundUrl
            }
        })
        setIsMeditating(true);
    }

    function togglePlaybackOnPressEnter(e) {
        if (e.key === "Enter") {
            audioProps.togglePlaybackStatus();
        }
    }

    return (
        <>
            { !props.showAll && !props.grid && (
                <MeditationCardStyled id={props.id} className={`max-visible-${props.visibleCount}`} onClick={openModal}
                                      tabIndex="0" aria-label={props.meditation.title + ". Opens meditation modal"}>
                    <img src={props.meditation.image} alt={altText} copyright={props.meditation.attribution}/>
                    <div className="subtext-container" aria-hidden="true">
                        <div className="subtext" aria-hidden="true">{props.meditation.title}</div>
                    </div>
                </MeditationCardStyled>
            )}
            { props.showAll && !props.grid && (
                <MeditationCardStyledAlt id={props.id} className={`show-all`} onClick={openModal}>
                    <img src={props.meditation.image} alt={altText} copyright={props.meditation.attribution}
                         tabIndex="0" aria-label={props.meditation.title + ". Opens meditation modal"} />
                    <div className="subtext-container" aria-hidden="true">
                        <div className="subtext" aria-hidden="true">{props.meditation.title}</div>
                    </div>
                </MeditationCardStyledAlt>
            )}
            { props.grid && (
                <MeditationGridStyledAlt id={props.id} onClick={openModal} className={props.myClass} onKeyDown={handlePressEnter}>
                    <img src={props.meditation.image} alt={altText} copyright={props.meditation.attribution}
                         tabIndex="0" aria-label={props.meditation.title + ". Opens meditation modal"} />
                    <div className="subtext-container" aria-hidden="true">
                        <div className="subtext" aria-hidden="true">{props.meditation.title}</div>
                    </div>
                </MeditationGridStyledAlt>
            )}

            { isOpen && (
                    <Modal themeColor={themeColor} onClick={(e) => handleOutsideClick(e)}>
                        <div className="modal-container" onClick={e => e.stopPropagation()}>
                            <img className={isMeditating ? 'image-show' : ''} src={props.meditation.image} alt={altText} title="Meditations Modal" copyright={props.meditation.attribution}></img>
                            <button className="modal-close" onClick={(e) => handleOutsideClick(e)} role="button" aria-label="Close meditations overlay"></button>
                            { props.meditation.attribution &&
                                <div className="copyright">{props.meditation.attribution}</div>
                            }
                            {!isMeditating && (
                                <div className="modal-controls">
                                    <div className="modal-title" tabIndex="0">{props.meditation.title}</div>
                                    <div className="modal-description" tabIndex="0">{props.meditation.description}</div>
                                    <div tabIndex="0">Background sound</div>
                                    <div className="modal-sounds">
                                        <button className={soundName === "None" ? "selectedSound" : ""} onClick={(e) => changeSound(e)}>None</button>
                                        {props.sounds.map((sound) => {
                                            return <button className={soundName === sound.name ? "selectedSound" : ""} onClick={(e) => changeSound(e)}>{sound.name}</button>
                                        })}
                                    </div>
                                    <button className="modal-start"
                                        onClick={() => startMeditation()}>
                                        Start Meditation
                                    </button>
                                </div>
                            )}
                            {isMeditating && (
                                <div>
                                    <div className="screenreader-only" aria-label={audioProps.playbackStatus === 'play' ? 'pause' : 'play'}>
                                            {audioProps.playbackStatus === 'play' ? 'pause' : 'play'}
                                    </div>
                                    <div
                                        className="play-button-container"
                                        ref={playButtonRef}
                                        role="button"
                                        tabIndex="0"
                                        aria-label={audioProps.playbackStatus === 'play' ? 'pause' : 'play'}
                                        onClick={audioProps.togglePlaybackStatus}
                                        onKeyDown={togglePlaybackOnPressEnter}
                                    >                                        
                                        <svg width="185" height="185" viewBox="0 0 185 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d)">
                                            <circle cx="92.5" cy="89.5" r="86.5" fill="white" fill-opacity="0.501961"/>
                                            </g>
                                            <g filter="url(#filter1_d)">
                                            <circle cx="92.5" cy="89.5" r="76.5" fill="white"/>
                                            </g>
                                            {audioProps.playbackStatus === "play" && (
                                                <>
                                                    <rect x="72" y="61" width="15" height="58" fill={themeColor}/>
                                                    <rect x="99" y="61" width="15" height="58" fill={themeColor}/>
                                                </>
                                            )}
                                            {audioProps.playbackStatus === "pause" && (
                                                <polygon points="65 50, 0 65, 15 0" width="100" height="58" transform="translate(83 135) rotate(225 0 0)" fill={themeColor}/>
                                            )}
                                            <defs>
                                            <filter id="filter0_d" x="0" y="0" width="185" height="185" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="3"/>
                                            <feGaussianBlur stdDeviation="3"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.160784 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                            </filter>
                                            <filter id="filter1_d" x="10" y="10" width="165" height="165" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                            <feOffset dy="3"/>
                                            <feGaussianBlur stdDeviation="3"/>
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.160784 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                            </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div>{audioElement}</div>
                                    <RemainingTime
                                        currentTime={audioProps.currentTime}
                                        isSeeking={audioProps.isSeeking}
                                        duration={audioProps.duration}
                                        setTime={audioProps.setTime}
                                        tabIndex="-1"
                                    />
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
        </>

    );
}