import React, { useContext } from 'react';
import { HorizontalCardStyled } from './CardStyles.js';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import { ColorizedImage } from '../Page';
import { setLinkFromApiAction } from '../../lib/util';

export default function HorizontalCard(props) {
    const { analytic_name_prefix, page_id, data, action } = props;
    const { token } = useContext(GlobalContext);
    const icon = data.image;
    const tintColor = data.image?.tint_color?.light;
    const canvasSizes = {
        // needed in case icons need colorized
        small: 28,
        medium: 40,
        large: 40
    }
    const link = setLinkFromApiAction(action);
    
    return (
        <HorizontalCardStyled
            href={link}
            item={data}
            target={action.type === "web" || action.web_redirect ? "_blank" : "_self"}
            rel="noreferrer"
            aria-label={data.title + ". " + data.message + ". Link opens in a new tab"}
            onClick={() => postAnalytics(token, {
                "event_name": analytic_name_prefix + "_tap",
                "event_data": {"page_id": page_id, action}
            })}>
            <div className="flex-item-1" aria-hidden="true">
                {!tintColor ?
                    <img src={icon.light} alt="icon" title="Icon" /> :
                    <ColorizedImage imageSrc={icon.light} colorHex={icon.tint_color.light} canvasSizes={canvasSizes} />
                }
            </div>
            <div className="flex-item-2" aria-hidden="true">
                <h4>{data.title}</h4>
                <span className="subtext">{data.message}</span>
            </div>
        </HorizontalCardStyled>
    );
}