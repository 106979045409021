import React, { useEffect, useState, useRef, useContext } from 'react';
import Carrousel from '../Carrousel';
import { MultiBlogFeedCard } from '../Cards';
import { PageSection } from '../Page';
import { getMultiBlogsData } from '../../common/api';
import GlobalContext from '../../GlobalContext';
import { SectionPlaceholder}  from '../Loading';

export default function MultiBlogFeedCards(props) {
    const { data } = props;
    const [blogData, setBlogData] = useState();
    const visibleCount = useRef(3);
    const { token } = useContext(GlobalContext);
    const [isLoading, setIsLoading] = useState(true);
    const title = data.top_section?.title?.value?.toLowerCase();
    useEffect(() => {
        if(token) {
            setData();
        }

        async function setData() {
            setIsLoading(true);
            const res = await getMultiBlogsData(token, data.feed_type);
            if (!res.success) return;
            
            if (title && title.indexOf("recipes") !== -1){
                visibleCount.current = "2";
                res.items.splice(6);            
            } else {
                visibleCount.current = "3";
                res.items.splice(9);
            }

            setIsLoading(false);
            setBlogData(res);
        }
    }, [token]);

    return (
        <div>
            {blogData && !isLoading &&
            <PageSection {...props}
                        heading={data.top_section?.title?.value} 
                        subheading={data.top_section?.subtitle?.value}
                        linkText={data.bottom_section?.button?.text?.value} 
                        link={blogData.link}>
                <Carrousel visibleCount={visibleCount.current} max={blogData.items.length} showControls="true">
                    {blogData.items.map((item) => {
                        return <MultiBlogFeedCard  {...props}
                                                  key={item.id} 
                                                  visibleCount={visibleCount.current}
                                                  imageUrl={item.image_url}
                                                  title={item.title} 
                                                  description={item.description}
                                                  link={item.link} />
                    })}
                </Carrousel>
            </PageSection>
            }
            
            {isLoading && 
                <PageSection {...props}
                            heading={data.top_section?.title?.value} 
                            subheading={data.top_section?.subtitle?.value}
                            linkText={data.bottom_section?.button?.text?.value}>
                    <SectionPlaceholder section={title} />
                </PageSection>
            }
        </div>
    );
}