import React, { useEffect, useState, useContext } from 'react';
import { Modal } from './';
import { DynamicPage } from './';
import GlobalContext from '../../GlobalContext';

export default function InternalPageOverlay(props) {
    const { show, setShow, data, setData, getData } = props.internalOverlayPage;
    const [apiStatus, setApiStatus] = useState({success: true, httpCode: ""});
    const { token } = useContext(GlobalContext);

    useEffect(() => {
        if (token) {
            fetchData();
            async function fetchData() {
                try {
                    const res = await getData();
                } catch (error) {
                    setApiStatus({
                        success: false,
                        httpCode: error.http_code ? error.http_code : "500"
                    });
                }                
            }
        }
    }, [token]);

    function closeModal() {
        setShow(false);
        window.history.replaceState(null, '', '/');
    }

    return (
        <Modal onClick={closeModal}>
            <DynamicPage {...props} data={data} apiStatus={apiStatus} />
        </Modal>    
    );
}