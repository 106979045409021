import React, { useEffect, useRef, useState } from 'react';
import GetWindowSize from '../../hooks/GetWindowSize';

export default function ColorizedImage({imageSrc, colorHex, canvasSizes}) {
    const [canvasSize, setCanvasSize] = useState(canvasSizes.large);
    const windowSize = GetWindowSize();    
    const theCanvas = useRef();
    const tintColor = '#aaaaaa';
    
    useEffect(() => {
        drawImage();
    }, [canvasSize]);

    useEffect(() => {        
        if (tintColor) {
            // set width and height of canvas based on screen size
            // canvases sized get passed in as prop from parent component since different components may need different sizes
            if (windowSize.width <= 480 && canvasSize !== canvasSizes.small) {
                setCanvasSize(canvasSizes.small);
            } else if (windowSize.width > 480 && windowSize.width < 768 && canvasSize !== canvasSizes.medium) {
                setCanvasSize(canvasSizes.medium);
            } else if (windowSize.width > 768 && canvasSize !== canvasSizes.large) {
                setCanvasSize(canvasSizes.large);
            }
        }
    }, [windowSize]);

    function drawImage() {
        let canvas = theCanvas.current;
        if (canvas !== null) {
            canvas.width = canvasSize;
            canvas.height = canvasSize;

            let ctx = canvas.getContext("2d");

            let img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = imageSrc;

            img.onload = function () {                  
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                let map = ctx.getImageData(0, 0, canvas.width, canvas.height);
                if (colorHex) {
                    let imdata = map.data;
                    // convert image to grayscale
                    let r,g,b,avg;
                    for(let p = 0, len = imdata.length; p < len; p += 4) {
                        r = imdata[p];
                        g = imdata[p + 1];
                        b = imdata[p + 2];
                        // alpha channel (p+3) is ignored
                        avg = Math.floor((r + g + b) / 3);
                        imdata[p] = imdata[p + 1] = imdata[p + 2] = avg;
                    }
                    ctx.putImageData(map, 0, 0);
                    // overlay filled rectangle using composition
                    ctx.globalCompositeOperation = "source-in";
                    ctx.globalAlpha = 1;
                    ctx.fillStyle = colorHex;
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                }
            };
        }
    }

    return (
        <div className="colorized-image-container">
            <canvas ref={theCanvas} />
        </div>
    );
}
