import React from 'react';
import { PageHeaderStyled } from './PageStyles.js';

export default function PageHeader(props) {
    return (
        <PageHeaderStyled>
            <h1 className="title">{props.title}</h1>
            {props.description &&
                <h3 className="description">{props.description}</h3>
            }
        </PageHeaderStyled>
    );
}