import React from 'react';
import ErrorPage from '../Error/ErrorPage';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error) {
      console.log(`Error processing data: ${error}`);
    }

    componentDidUpdate(prevProps) {
      if (this.props.onRoute) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
          this.setState({ hasError: false });
        }
      }
    }
  
    render() {
      if (this.state.hasError) {
        // if error occurs in main route components, return error page
        // else don't return anything
        if (this.props.onRoute) {
          return <ErrorPage httpCode="Error"/>;
        } else {
          return <></>
        }
      }
  
      return this.props.children; 
    }
  }